import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  border-bottom: 1px solid ",
        ";\n  color: ",
        ";\n  padding: 16px;\n  vertical-align: middle;\n\n  ",
        "\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  color: ",
        ";\n  font-size: 12px;\n  text-transform: uppercase;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { typography } from "styled-system";
export var Td = styled.td.withConfig({
    componentId: "sc-ea0e7240-0"
})(_templateObject(), function(param) {
    var theme = param.theme;
    return theme.colors.cardBorder;
}, function(param) {
    var theme = param.theme;
    return theme.colors.text;
}, typography);
export var Th = styled(Td).attrs({
    as: "th"
}).withConfig({
    componentId: "sc-ea0e7240-1"
})(_templateObject1(), function(param) {
    var theme = param.theme;
    return theme.colors.secondary;
});

import styled from 'styled-components'
import Page from '../Layout/Page'
import TrendydefiSpinner from './TrendydefiSpinner'

const Wrapper = styled(Page)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: transparent;
    width: 36px;
    height: 36px;
    border-radius: 50%;

    animation: spin 1s linear infinite;
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }
`

const TrendyPageLoader: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Wrapper>
      <TrendydefiSpinner />
      {/* <div className='spinner'>

      </div> */}
    </Wrapper>
  )
}

export default TrendyPageLoader

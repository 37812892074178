import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { getContract, getPoolsContract } from 'utils/contractHelpers'
import addresses from '../../config/constants/contracts'
import voteAbi from '../../config/abi/vote.json'
import { useBalance, useNetwork, useSigner } from 'wagmi'
import { useWeb3React } from '../../../packages/wagmi/src/useWeb3React'
import refferalAbi from 'config/abi/refferal.json'
import TrendyPageLoader from 'components/Loader/TrendyPageLoader'
import { ModalCheckRegister } from 'components/ModalRegister/ModalCheckRegister'
import { Button, Text, useModal, useToast } from '@pancakeswap/uikit'
import useVoteConfirmTransaction from 'hooks/useVoteConfirmTransaction'
import { ToastDescriptionWithTx } from 'components/Toast'
import { useTranslation } from '@pancakeswap/localization'
import styled from 'styled-components'
import { formatBigNumber } from 'utils/formatBalance'
import CountUp from 'react-countup'
import { ChainId, NATIVE } from '../../../packages/swap-sdk/src/constants'
import { formatEther } from '@ethersproject/units'
import { ethers } from 'ethers'
import numeral from 'numeral'
import { useWallet } from 'hooks/useWallet'
const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100vh;
  margin: 150px 0;
  position: relative;
  padding: 20px;
  @media screen and (max-width: 575px) {
    margin: 80px 0;
  }
`
const Container = styled.div`
  align-items: center;
  display: flex;
  border-radius: 20px;
  border: 4px solid #ff9400;
  padding: 40px;
  gap: 20px;
  flex-direction: column;
  max-width: 855px;
  @media screen and (max-width: 575px) {
    padding: 20px 10px;
  }
`
const DateTime = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 490px;
  border-radius: 12px;
  padding: 0 20px;
  background: #ffffff1a;
  border: 1px solid #8544f5;
  height: 49px;
  align-items: center;
  @media screen and (max-width: 575px) {
    width: 100%;
  }
`
const TextContainer = styled.div`
  max-width: 660px;
  margin-bottom: 10px;
  @media screen and (max-width: 575px) {
    width: 100%;
  }
`
const TextContent = styled(Text)`
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;

  letter-spacing: 0em;
  word-wrap: break-word;
  text-align: justify;
  color: #adabb2;
  @media screen and (max-width: 575px) {
    font-size: 14px;
    line-height: 18px;
  }
`
const Progress = styled.div`
  width: 624px;
  border-radius: 20px;
  progress {
    width: 100%; /* Set the width of the progress bar */
    height: 20px; /* Set the height of the progress bar */
    appearance: none; /* Remove default styling */
    border-radius: 20px; /* Add rounded corners */
    background-color: #f0f0f0; /* Set background color of the progress bar */
  }

  /* Styling the progress bar track */
  progress::-webkit-progress-bar {
    background-color: #f0f0f0; /* Set background color of the progress bar track */
    border-radius: 5px; /* Add rounded corners */
  }

  /* Styling the progress bar value */
  progress::-webkit-progress-value {
    background-color: #ff9400; /* Set background color of the progress bar value */
    border-radius: 5px; /* Add rounded corners */
  }
  @media screen and (max-width: 575px) {
    width: 100%;
  }
`
const Title = styled(Text)`
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: -0.02em;
  text-align: center;
  margin: 20px 0;
  @media screen and (max-width: 575px) {
    font-size: 18px;
    line-height: 24px;
  }
`
const Label = styled(Text)`
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  color: #adabb2;
  text-align: center;
  @media screen and (max-width: 575px) {
    font-size: 16px;
    line-height: 24px;
  }
`
function Vote() {
  let CHAIN_ID = Number(process.env.NEXT_PUBLIC_DEFAULT_CHAIN)
  const { account, chainId } = useWeb3React()
  const { data: signer } = useSigner()
  const voteCt = getContract({ address: addresses.vote[CHAIN_ID], abi: voteAbi, chainId: CHAIN_ID, signer })
  const refferCT = getContract({ address: addresses.refferal[CHAIN_ID], abi: refferalAbi, chainId: CHAIN_ID, signer })
  const [tagert, setTagert] = useState(0)
  const [loadingPage, setLoadingPage] = useState(false)
  const [isRegister, setIsRegister] = useState(true)
  const [usersIsVote, setUserIsVote] = useState(false)
  const [totalVoted, setTotalVoted] = useState(0)
  const [userVoted, setUserVoted] = useState(0)
  const getPoolContract = getPoolsContract(CHAIN_ID)
  const [totalLock, setToalLock] = useState(0)
  const { toastSuccess, toastError } = useToast()
  const { t } = useTranslation()
  const ref = useRef(null)
  const { onPresentConnectModal } = useWallet()
  const { chains, chain } = useNetwork()

  //   const isVisible = useOnScreen(ref)
  const [openModal, onDismiss] = useModal(
    <ModalCheckRegister onCheck={() => checkRegister()} onDismiss={() => onDismiss} />,
    true,
    false,
    'removeModalCheckRegister',
  )

  useEffect(() => {
    if (account) {
      checkRegister()
      checkVote()
      userTotal()
    }
    getBalance()
  }, [account])

  const checkRegister = async () => {
    if (!account) {
      setLoadingPage(true)
    } else {
      setLoadingPage(false)
      const usersRegister = await refferCT.isReferrer(account)
      setIsRegister(usersRegister)
    }
  }
  const handleRegister = () => {
    openModal()
  }
  const checkVote = async () => {
    if (!account) {
      setLoadingPage(true)
    } else {
      setLoadingPage(false)
      const users = await voteCt.users(account)
      setUserIsVote(users)
    }
  }
  const { isVotting, isVoted, isConfirmedVote, isConfirmingVote, handleVote, handleConfirmVote } =
    useVoteConfirmTransaction({
      onRequiresVoteval: async () => {
        return Promise.resolve(true)
      },
      onVote: () => {
        setLoadingPage(true)
        return voteCt.vote()
      },
      onVoteSuccess: async ({ receipt }) => {
        toastSuccess(t('Congratdataulations vote success'), <ToastDescriptionWithTx txHash={receipt.transactionHash} />)
        setLoadingPage(false)
        await checkVote()
      },
      onConfirm: (receipt) => {
        return receipt
      },
      onSuccess: async ({ receipt }) => {
        setLoadingPage(false)
        return Promise.resolve(1)
      },
    })
  // const { data, isFetched } = useBalance({
  //   addressOrName: addresses.pools[CHAIN_ID],
  // })
  // //   const { data: data2, isFetched: isFetched2 } = useBalance({
  // //     addressOrName: addresses.poolsV2[CHAIN_ID],
  // //   })
  // const balance = isFetched && data && data.value ? formatBigNumber(data.value, 6) : 0
  const getBalance = async () => {
    const currentChain = chains.find((c) => c.id === Number(process.env.NEXT_PUBLIC_DEFAULT_CHAIN))
    const polygon = currentChain.rpcUrls.public
    const provider = ethers.getDefaultProvider(polygon)
    let balance = await provider.getBalance(addresses.pools[CHAIN_ID])
    setTagert(Number(formatBigNumber(balance, 6)))
  }
  const userTotal = async () => {
    if (!account) {
      setLoadingPage(true)
    } else {
      setLoadingPage(false)
      const total = await voteCt.userTotalLockVoted()
      const totalLockV2 = await getPoolContract.usdTotalLock()
      setToalLock(Number(formatEther(totalLockV2)))
      setTotalVoted(Number(formatBigNumber(total, 6)))
      const uv = await voteCt.getUserVote().then((rs) => rs.length)
      setUserVoted(uv)
    }
  }

  return (
    <>
      {loadingPage === true ? (
        <TrendyPageLoader />
      ) : (
        <Wrapper>
          <Container
            style={{
              background: '#1D1617',
            }}
          >
            <div style={{ display: 'flex', width: '120px', alignItems: 'flex-start' }}>
              <img src="/images/core/Logo.png" />
            </div>
            {/* <DateTime>
              <div  console.log(currentChain)

                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '10px',
                }}
              >
                <h1 style={{ color: '#ADABB2' }}>Start date: 10/04/2024</h1>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '10px',
                }}
              >
                <h1 style={{ color: '#ADABB2' }}>End date: 13/04/2024</h1>
              </div>
            </DateTime> */}
            {/* <Title>Proposal: Eliminating the MLM feature from the system and standardizing the Referral system.</Title> */}
            <Label>
              Attention Core Stake Community! We&apos;re excited to introduce the Vote DAO, empowering CST miners to
              engage in CST Staking decisions. Upon achieving 51% consensus, CST staking will be launched, fostering
              decentralized governance and community-driven growth!
            </Label>
            {/* <TextContainer>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '10px',
                  margin: '15px 0',
                  alignItems: 'center',
                }}
              >
                <Text style={{ color: '#8544F5' }} fontSize={24} fontWeight={700}>
                  ✦
                </Text>
                <TextContent>
                  The voting period will take place from 3:00 PM on April 10th to 3:00 PM on April 13th, 2024 (UTC+7).
                </TextContent>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '10px',
                  margin: '15px 0',
                  alignItems: 'center',
                }}
              >
                <Text style={{ color: '#8544F5' }} fontSize={24} fontWeight={700}>
                  ✦
                </Text>
                <TextContent>
                  All Trendy investors are eligible to vote (each person is entitled to one vote, regardless of the
                  amount invested).
                </TextContent>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '10px',
                  margin: '15px 0',
                  alignItems: 'center',
                }}
              >
                <Text style={{ color: '#8544F5' }} fontSize={24} fontWeight={700}>
                  ✦
                </Text>
                <TextContent>If there are more than 51% consensus rate, the proposal will be implemented.</TextContent>
              </div>
            </TextContainer> */}
            <Progress>
              <progress value={totalVoted} max={tagert} />
              <div style={{ display: 'flex', justifyContent: 'space-between', color: 'white', marginBottom: 10 }}>
                <span>0%</span>
                <span>
                  voted: {userVoted} - {(((totalVoted > tagert ? tagert : totalVoted) * 100) / tagert).toFixed(0)} %
                </span>
                <span>100%</span>
              </div>
            </Progress>
            {!account ? (
              <Button
                style={{
                  width: '200px',
                  height: '41px',
                  borderRadius: '20px',
                  fontWeight: '700',
                  cursor: 'pointer',
                  padding: '20px',
                  marginBottom: 10,
                  boxShadow: '2px 2px 8px 16px #0000001A',
                  background: '#FF9400',
                }}
                type="button"
                onClick={onPresentConnectModal}
              >
                <h1 style={{ fontSize: '20px', color: '#E2E1E5' }}>Connect Wallet</h1>
              </Button>
            ) : (
              <>
                {isRegister === false ? (
                  <Button
                    style={{
                      width: '161px',
                      height: '41px',
                      borderRadius: '20px',
                      fontWeight: '700',
                      cursor: 'pointer',
                      padding: '20px',
                      marginBottom: 10,
                      boxShadow: '2px 2px 8px 16px #0000001A',
                      background: '#FF9400',
                    }}
                    type="button"
                    onClick={handleRegister}
                  >
                    <h1 style={{ fontSize: '20px', color: '#E2E1E5' }}>Register</h1>
                  </Button>
                ) : (
                  <Button
                    style={{
                      width: '161px',
                      height: '41px',
                      borderRadius: '20px',
                      fontWeight: '700',
                      cursor: 'pointer',
                      padding: '20px',
                      marginBottom: 10,
                      boxShadow: '2px 2px 8px 16px #0000001A',
                      background: '#FF9400',
                    }}
                    type="button"
                    className="buttonVote"
                    onClick={handleVote}
                    disabled={usersIsVote}
                  >
                    <h1 style={{ fontSize: '20px', color: '#E2E1E5' }}>Vote</h1>
                  </Button>
                )}
              </>
            )}
          </Container>
        </Wrapper>
      )}
    </>
  )
}

export default Vote

import { Divider, Flex, Stack, Text } from '@chakra-ui/react'
import { useMatchBreakpoints } from '@pancakeswap/uikit'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 250px 50px;
  z-index: 2;
  background-image: url('/images/core/section3.png');
  background-size: cover;
  background-position: 50% 100%;
  background-repeat: no-repeat;
  @media screen and (max-width: 1200px) {
    padding: 50px;
  }
  @media screen and (max-width: 900px) {
    padding: 10px;
  }
`
const ButtonLink = styled.a`
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ff8000;
  background: #ff9400;
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`
const data = [
  {
    number: '/images/core/1.svg',
    title: '/images/core/frameWallet.svg',
    desc: 'Connect your wallet',
  },
  {
    number: '/images/core/2.svg',
    title: '/images/core/priceFrame.svg',
    desc: 'Input the amount of $ (Min: $100) to stake',
  },
  {
    number: '/images/core/3.svg',
    title: '/images/core/enableFrame.svg',
    desc: 'Select “ENABLE STAKE” button',
  },
  {
    number: '/images/core/4.svg',
    title: '/images/core/confirmFrame.svg',
    desc: 'Confirm transaction in your wallet',
  },
]
const Section3 = () => {
  const { isMobile, isTablet } = useMatchBreakpoints()

  return (
    <Wrapper>
      {/* <div
        style={{
          position: 'absolute',
          left: '45%',
          top: '26.9%',
          display: isMobile ? 'none' : isTablet ? 'none' : 'flex',
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="128" height="18" viewBox="0 0 128 18" fill="none">
          <path d="M113.361 17L126 1L2 0.999989L14.6391 17L113.361 17Z" fill="#00040F" stroke="black" />
        </svg>
      </div> */}
      <Stack
        gap="50px"
        justifyContent="center"
        alignItems="center"
        p={isMobile ? '20px 10px' : isTablet ? '20px' : '0 50px'}
      >
        <Text
          color="#FFF"
          textAlign="center"
          fontFamily="Poppins,sans-serif"
          fontSize="48px"
          fontStyle="normal"
          fontWeight={300}
          lineHeight="130%"
        >
          How to use{' '}
          <span
            style={{
              color: '#FFF',
              fontFamily: 'Poppins,sans-serif',
              fontSize: '48px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '130%',
            }}
          >
            staking App
          </span>
        </Text>
        <Flex direction={isMobile ? 'column' : isTablet ? 'column' : 'row'} w="100%" alignItems="start" gap="30px">
          <Stack w={isMobile ? '100%' : isTablet ? '100%' : '50%'} gap="22px">
            <Text fontSize="16px" fontWeight={400} color="rgba(255, 255, 255, 0.7)">
              Before following the steps to stake your CORE, you will need to first Buy CORE coin. Get your CORE and
              then follow the steps on this app to stake your CORE and earn CORE Coin.
            </Text>
            <Divider color="rgba(255, 255, 255, 0.15)" w={isMobile ? '100%' : '90%'} />

            <Stack gap="60px">
              {data.map((i, index) => (
                <Flex alignItems="center" gap="20px" key={i.desc}>
                  <img src={i.number} alt="" />
                  <img src={i.title} alt="" />
                  <Text style={{ color: '#fff' }} fontSize="20px" fontWeight={700} width={isMobile ? '100%' : '400px'}>
                    {i.desc}
                  </Text>
                </Flex>
              ))}
            </Stack>
            <Stack width={isMobile ? '100%' : isTablet ? '100%' : '90%'} alignItems="center" justifyContent="center">
              <ButtonLink href="https://www.okx.com/vi/trade-spot/core-usdt">Buy Core</ButtonLink>
            </Stack>
          </Stack>
          <Stack w={isMobile ? '100%' : isTablet ? '100%' : '50%'} alignItems={isTablet ? 'center' : 'flex-end'}>
            <img
              style={{ maxWidth: '573px', maxHeight: '621px' }}
              width="100%"
              height="100%"
              src="/images/core/bannerRefer2.png"
              alt=""
            />
          </Stack>
        </Flex>
      </Stack>
    </Wrapper>
  )
}
export default Section3

import { formatEther } from '@ethersproject/units'
import { Text, useMatchBreakpoints } from '@pancakeswap/uikit'
import poolAbi from 'config/abi/pools.json'
import refferalAbi from 'config/abi/refferal.json'
import addresses from 'config/constants/contracts'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { getContract } from 'utils/contractHelpers'
import { useSigner } from 'wagmi'
import PoolRanks from './components/rank'
import Breadcrumb from 'components/Breadcrumb'
import { Stack, Flex, Divider } from '@chakra-ui/react'
import { usePoolsV2Contract } from '../../hooks/useContract'

const Wrapper = styled.div`
  width: 100%;
  margin: 150px 0;
`
const Title = styled(Text)`
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px; /* 125% */
  letter-spacing: -0.96px;
  color: white;
  margin-bottom: 50px;
  margin: 0 auto;
  display: flex;
  max-width: 1204px;

  @media (max-width: 575px) {
    padding: 16px;
  }
`
const BreadcrumbTitle = styled(Text)`
  color: rgba(255, 255, 255, 0.7);
  opacity: 0.5;
  font-family: Poppins;
  margin: 0 auto;
  display: flex;
  max-width: 1204px;

  @media (max-width: 575px) {
    padding: 16px;
  }
`
const Leaderboard = () => {
  const getPoolsContractV2 = usePoolsV2Contract()
  const { account, chainId } = useActiveWeb3React()
  // account = '0xBd0b331A65C686085FC892D1b7A7B06C54a8858b'
  const [arrayTotalLock, setArrayTotalLock] = useState([])
  const { data: signer } = useSigner()
  const CHAIN_ID = Number(process.env.NEXT_PUBLIC_DEFAULT_CHAIN)
  const refferCT = getContract({ address: addresses.refferal[CHAIN_ID], abi: refferalAbi, chainId: CHAIN_ID, signer })
  const poolsCT = getContract({
    address: addresses.pools[CHAIN_ID],
    abi: poolAbi,
    chainId: CHAIN_ID,
    signer,
  })
  const [userRank, setUserRank] = useState({
    direct: 0,
    volumnOnTree: 0,
  })
  const getData = async () => {
    const [users, getVolume, getVolume2] = await Promise.all([
      refferCT.userInfos(account),
      poolsCT.getVolumeOnTre(account),
      getPoolsContractV2.getVolumeOnTre(account),
    ])
    setUserRank({
      direct: users.totalRefer.toString(),
      volumnOnTree: Number(formatEther(getVolume.add(getVolume2))),
    })
    const dataUsers = await refferCT.getTotalUserByUp(account, users.totalRefer.toString(), 0)
    if (dataUsers.list.length > 0) {
      const arr = dataUsers.list.map((item) => item.user)
      const totalLockUSD = await Promise.all(arr.map((items) => poolsCT.userTotalLockUSD(items)))
      setArrayTotalLock(totalLockUSD)
    }
  }
  useEffect(() => {
    if (account) {
      getData()
    } else {
      setUserRank({
        direct: 0,
        volumnOnTree: 0,
      })
    }
  }, [account])

  return (
    <Wrapper>
      <Stack>
        <BreadcrumbTitle>
          <Breadcrumb
            fontSize="14px"
            links={[
              { title: 'Home', href: '/' },
              { title: 'LeaderBoard', href: '/leaderboard' },
            ]}
            current="leaderboard"
            justifyContent="center"
          />
        </BreadcrumbTitle>
        <Title>LeaderBoard</Title>
      </Stack>
      <PoolRanks userRank={userRank} arrayTotalLock={arrayTotalLock} />
    </Wrapper>
  )
}
export default Leaderboard

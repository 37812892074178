import { Button, Heading, Input, Text, useMatchBreakpoints } from '@pancakeswap/uikit'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Tooltip } from 'react-tooltip'
import truncateHash from '@pancakeswap/utils/truncateHash'
import { Text04 } from 'configs/fornt'
import CountUp from 'react-countup'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import Breadcrumb from 'components/Breadcrumb'
import { Stack, Flex, Divider } from '@chakra-ui/react'
const ReferralPage = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: center;
  gap: 48px;
  width: 78%;
  .btnRefer:disabled {
    background: rgba(153, 153, 153, 0.6);
  }
  .btnRefer {
    background: linear-gradient(90deg, #ffbd33 0%, #ff8000 100%);
    color: #FFF;
    border: none;
  }
  @media screen and (max-width: 1440px) {
  width: 100%;

  }
  @media screen and (max-width: 900px) {
  width: 100%;

  }
  @media screen and (max-width: 575px) {
    padding 0px 16px;
  width: 100%;

  }
`
const StyledHead = styled(Heading)`
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: 56px; /* 125% */
  letter-spacing: -0.96px;
  color: #fff;
  @media screen and (max-width: 575px) {
    font-size: 55px;
    align-self: stretch;
    line-height: 130%;
  }
`
const StyledSubtitle = styled(Text)`
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: 4px;
  width: 100%;
  text-align: center;
  color: white;
  @media screen and (max-width: 575px) {
    font-size: 16px;
    margin-top: 16px;
    line-height: 24px;
    text-align: start;
  }
  .title {
    color: white;
    font-weight: 700;
  }
`
const CardRegister = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  padding: 40px;

  position: relative;
  .upto {
    position: absolute;
    top: -12%;
    right: -7%;
  }
  @media screen and (max-width: 575px) {
    padding: 30px 0;
    .upto {
      position: relative;
      top: 0;
      right: 0;
      width: 343px;
    }
  }
`
const GroupLinkRef = styled.div`
  flex-direction: row;
  display: flex;
  gap: 20px;
  width: 100%;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`
const StyledText = styled(Text)`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: rgba(173, 171, 178, 1);
`
const WrapperLinkRef = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 50%;
  border-radius: 16px;
  border: 1px solid transparent;

  background-image: linear-gradient(#1d1617, #1d1617), linear-gradient(180deg, #ff9400 0%, #d97059 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  padding: 32px;
  gap: 32px;
  @media screen and (max-width: 900px) {
    width: 100%;
  }
  @media screen and (max-width: 575px) {
    padding: 16px;
    width: 100%;
  }
`

const GroupLink = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  .btnRefer:disabled {
    background: rgba(153, 153, 153, 0.6);
  }
  .btnRefer {
    background: linear-gradient(90deg, #ffbd33 0%, #ff8000 100%);
    color: #fff;
    border: none;
  }
  @media screen and (max-width: 575px) {
    flex-direction: column;
  }
`
const StyledLink = styled.div`
  width: 100%;
  border-radius: 12px;
  background: var(--greyscale-grayscale-4, #2d2c33);
  border: none;
  outline: none;
  color: black;
  font-size: 18px;
  flex-direction: row-reverse;
  min-height: 34px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 20px;
  padding: 12px 16px;

  ${({ theme }) => theme.mediaQueries.md} {
    min-height: 44px;
  }
`
const LinkItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  width: 100%;
`
const ShowLinkRefPc = styled.span`
  display: none;
  word-break: break-all;
  color: #fff;
  ${({ theme }) => theme.mediaQueries.sm} {
    display: block;
  }
`
const ShowLinkRefMobile = styled.span`
  display: block;
  word-break: break-all;
  color: #fff;

  ${({ theme }) => theme.mediaQueries.sm} {
    display: none;
  }
`
const StyledIconRef = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`
const StyledInput = styled(Input)`
  outline: none;
  border-radius: 12px;
  background: var(--greyscale-grayscale-4, #2d2c33);
  padding: 12px 16px;
  height: 44px;
  &:focus:not(:disabled) {
    border: none;
  }
`
const StyledButton = styled(Button)`
  font-weight: 500;
  background: #ff9400;
  color: #fff;
  margin-top: 30px;
  font-size: 16px;
  line-height: 20px;
  border-radius: 90px;
  width: 192px;
  border: 2px solid var(--greyscale-grey-scale-text-seconday, #adabb2);
  @media screen and (max-width: 575px) {
    margin-top: 0px;
    width: 100%;
  }
`
const GroupSearchByCode = styled.div`
  .search {
    position: absolute;
    top: 20%;
    width: 24px;
    height: 24px;
    right: 2%;
    cursor: pointer;
  }
`
const StyledHeadSearchUser = styled(Heading)`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 12px;
`
const StyledInputSearch = styled(Input)`
  outline: none;
  height: 48px;
  border-radius: 12px;
  background: #0a0b0c;
  padding: 12px 16px;

  &:focus:not(:disabled) {
    border: none;
  }
  ::placeholder {
    color: #fff;
  }
`
const ButtonLink = styled.button`
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ff8000;
  background: #ff9400;
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`
const Step = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  justify-content: center;
  align-items: center;
`
const TitleStep = styled(Text)`
  font-size: 48px;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  color: rgba(255, 255, 255, 1);
  @media screen and (max-width: 575px) {
    font-size: 40px;
    text-align: start;
  }
`
const LabelStep = styled(Text)`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: rgba(173, 171, 178, 1);
  text-align: center;
`
const CardStep = styled.div`
  display: flex;
  margin-top: 24px;
  gap: 10px;
  justify-content: space-between;
  @media screen and (max-width: 900px) {
    width: 100%;
  }
  @media screen and (max-width: 575px) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
`
const CardImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('/images/core/Step1.png');
  background-size: 100% 100%;
  width: 260.843px;
  height: 92px;
  @media screen and (max-width: 900px) {
    width: 180px;
    height: 62px;
  }
`
const CardImage2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('/images/core/Step2.png');
  background-size: 100% 100%;
  width: 260.843px;
  height: 92px;
  @media screen and (max-width: 900px) {
    width: 180px;
    height: 62px;
  }
`
const CardImage3 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('/images/core/Step3.png');
  background-size: 100% 100%;
  width: 260.843px;
  height: 92px;
  @media screen and (max-width: 900px) {
    width: 180px;
    height: 62px;
  }
`
const Card = styled.div`
  width: 230px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  .imgStep {
    width: 48px;
    height: 48px;
  }
`
const CardTitle = styled(Text)`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: rgba(255, 255, 255, 1);
`
const BorderHead = styled.div`
  width: 100%;
  border: 1px dashed rgba(255, 254, 252, 0.5);
  border-radius: 18px;
  padding: 14px 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .imgNofi {
    width: 50%;
  }
  @media screen and (max-width: 900px) {
    .imgNofi {
      width: 70%;
    }
  }
  @media screen and (max-width: 575px) {
    padding: 30px 20px;
    justify-content: flex-start;
    .imgNofi {
      width: 100%;
    }
    .details {
      position: absolute;
      width: 106px;
      right: 4%;
      top: 15%;
    }
  }
`
const Percentage = styled(Text)`
  color: #ff8000;
  text-align: center;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px; /* 125% */
  letter-spacing: -0.96px;
`
const BlockInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  align-self: stretch;
  border-radius: 16px;
  margin-top: 48px;
  border-radius: 16px;
  @media screen and (max-width: 575px) {
    width: 100%;
  }
`

const InfoItem = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  color: #e6e6e6;
  //max-width: 386px;
  gap: 10px;
  border-radius: 16px;
  background: #2f292a;
  backdrop-filter: blur(6px);
  padding: 16px 24px;
  @media screen and (max-width: 1200px) {
    width: 300px;
  }
  @media screen and (max-width: 575px) {
    width: 100%;
    padding: 16px;
  }
`
const Label = styled(Text)`
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  color: #ffffff;
`
const Value = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  color: #fff;
  @media screen and (max-width: 575px) {
    font-size: 15px;
  }
  @media screen and (max-width: 375px) {
    font-size: 10px;
  }
`
const ValueCore = styled.div`
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  color: #fff;
  display: flex;

  gap: 10px;
  @media screen and (max-width: 575px) {
    font-size: 15px;
  }
  @media screen and (max-width: 375px) {
    font-size: 10px;
  }
`
const InfoGruop = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  align-items: center;
  justify-content: space-around;
  @media screen and (max-width: 1024px) {
    width: 100%;
    flex-direction: column;
    gap: 50px;
    align-items: center;
  }
  @media screen and (max-width: 900px) {
    width: 100%;
    flex-direction: column;
    gap: 50px;
    align-items: center;
  }
  @media screen and (max-width: 575px) {
    width: 100%;
    flex-direction: column;
    gap: 50px;
    align-items: center;
  }
`
const Item = styled.div`
  border-radius: 8px;
  padding: 30px;
  border: 2px solid transparent;

  background-image: linear-gradient(#1d1617, #1d1617), linear-gradient(180deg, #ff9400 0%, #d97059 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  display: flex;
  gap: 10px;
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: 1200px) {
    width: 100%;
  }

  @media screen and (max-width: 575px) {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    padding: 12px;
  }
`
const BreadcrumbTitle = styled(Text)`
  color: rgba(255, 255, 255, 0.7);
  opacity: 0.5;
  font-family: Poppins;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: 'center';
  @media (max-width: 575px) {
    // padding: 16px;
  }
`

const data = [
  {
    number: '/images/core/1.svg',
    title: '/images/core/shareBorder.svg',
    desc: 'Grab your referral link or code.',
  },
  {
    number: '/images/core/2.svg',
    title: '/images/core/addBorder.svg',
    desc: 'Invite friends to sign up and deposit more than $100 within 7 days of registration.',
  },
  {
    number: '/images/core/3.svg',
    title: '/images/core/gift.svg',
    desc: 'Both you and your friend receive a trading fee rebate voucher of the same amount.',
  },
]
const PageReferral = ({
  linkRef,
  handleRef,
  handleLeave,
  userIsRegister,
  showCopied,
  referCode,
  validateReferByWallet,
  showError,
  onRegister,
  userSearch,
  setUserSearch,
  handleSearchUserCode,
  showResultSearch,
  setTab,
  userInfos,
}) => {
  const { isMobile, isTablet, isTabletOrDesktop } = useMatchBreakpoints()
  const maxOut = userInfos.maxOutUSD / userInfos.corePrice
  const percentage = userInfos.maxOutUSD > 0 ? (userInfos.commClaimed / maxOut) * 100 : 0
  const formatLinkRef = (ref, start, end) => {
    if (ref?.includes('0x')) {
      return truncateHash(ref, start, end)
    }
    return linkRef
  }

  return (
    <ReferralPage>
      <Stack justifyContent={isMobile ? 'flex-start' : 'center'} alignItems={isMobile ? 'start' : 'center'}>
        <BreadcrumbTitle>
          <Breadcrumb
            fontSize="14px"
            links={[
              { title: 'Home', href: '/' },
              { title: 'Referral', href: '/referral' },
            ]}
            current="referral"
          />
        </BreadcrumbTitle>

        <StyledHead>Referral Program</StyledHead>
        <StyledSubtitle>Unlock exclusive rewards and benefits by joining our Referral Program!</StyledSubtitle>
        <StyledSubtitle>Share the love and refer friends to experience the best together.</StyledSubtitle>
      </Stack>
      <CardRegister>
        <GroupLinkRef>
          <img
            style={{ width: isMobile ? '100%' : isTablet ? '100%' : '50%' }}
            src="/images/core/bannerRefer1.png"
            alt=""
          />
          <WrapperLinkRef>
            <Flex gap="10px" alignItems="center">
              <img src="/images/core/addBorder.svg" alt="" />
              <Text
                fontSize={['24px']}
                fontWeight="600"
                lineHeight="24px"
                style={{ color: 'rgba(255, 255, 255, 1)' }}
                textAlign={isMobile ? 'start' : 'center'}
              >
                {' '}
                Invite friends <span style={{ color: '#FF9400' }}>to earn 10%</span>
              </Text>
            </Flex>
            <GroupLink>
              <div style={{ width: '100%' }}>
                <StyledText
                  style={{ color: 'rgba(255, 255, 255, 1)', marginBottom: '12px' }}
                  color="rgba(255, 255, 255, 1)"
                >
                  Referral link
                </StyledText>
                <StyledLink>
                  <LinkItem>
                    <ShowLinkRefPc>{formatLinkRef(linkRef, 52, 4)}</ShowLinkRefPc>
                    <ShowLinkRefMobile>{formatLinkRef(linkRef, 20, 4)}</ShowLinkRefMobile>
                    <StyledIconRef
                      id="iconRef"
                      src="/images/core/copy.svg"
                      onClick={handleRef}
                      onMouseLeave={handleLeave}
                    />
                    <Tooltip
                      anchorId="iconRef"
                      content={userIsRegister ? (showCopied ? 'Copied' : 'Copy') : 'Please Register'}
                    />
                  </LinkItem>
                </StyledLink>
              </div>
              {userIsRegister && (
                <GroupSearchByCode style={{ width: isMobile ? '100%' : '50%' }}>
                  <StyledHeadSearchUser>Search By Code</StyledHeadSearchUser>
                  <div style={{ position: 'relative' }}>
                    <StyledInputSearch
                      value={userSearch}
                      autoFocus={true}
                      onChange={(e) => setUserSearch(e.target.value)}
                      placeholder={`Enter code`}
                    />
                    <div onClick={handleSearchUserCode}>
                      <img className="search" src="./images/search.png" />
                    </div>
                  </div>
                  {showResultSearch()}
                </GroupSearchByCode>
              )}
              {!userIsRegister && (
                <>
                  <div style={{ width: '100%' }}>
                    <StyledText style={{ color: 'rgba(255, 255, 255, 1)', marginBottom: '12px' }}>
                      Referral code
                    </StyledText>
                    <div>
                      <StyledInput
                        src="/images/core/copy.svg"
                        value={referCode}
                        autoFocus={true}
                        onChange={validateReferByWallet}
                        placeholder={`Refer code`}
                      ></StyledInput>
                    </div>
                    {showError && referCode && <span style={{ color: 'red' }}>Wrong referal code</span>}
                  </div>
                  <Stack justifyContent="center" alignItems="center">
                    <StyledButton className="btnRefer" onClick={onRegister} disabled={userIsRegister || showError}>
                      Register
                    </StyledButton>
                  </Stack>
                </>
              )}
            </GroupLink>
            {!userIsRegister && (
              <>
                <div style={{ display: 'flex', gap: '32px', alignItems: 'center', justifyContent: 'center' }}>
                  <div style={{ width: '100px', height: '1px', background: 'rgba(255, 255, 255, 0.10)' }}></div>
                  <Text fontSize="18px" fontWeight="500" lineHeight="20px">
                    Or
                  </Text>
                  <div style={{ width: '100px', height: '1px', background: 'rgba(255, 255, 255, 0.10)' }}></div>
                </div>
                <GroupSearchByCode>
                  <StyledHeadSearchUser>Search By Code</StyledHeadSearchUser>
                  <div style={{ position: 'relative' }}>
                    <StyledInputSearch
                      value={userSearch}
                      autoFocus={true}
                      onChange={(e) => setUserSearch(e.target.value)}
                      placeholder={`Enter code`}
                    />
                    <div onClick={handleSearchUserCode}>
                      <img className="search" src="/images/core/search.svg" />
                    </div>
                  </div>
                  {showResultSearch()}
                </GroupSearchByCode>
              </>
            )}

            <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
              <ButtonLink onClick={() => setTab(3)}>Check my refer list</ButtonLink>
            </div>
          </WrapperLinkRef>
        </GroupLinkRef>
        <BlockInfo>
          <Text
            fontSize={['48px']}
            fontWeight="600"
            lineHeight="24px"
            style={{ color: 'rgba(255, 255, 255, 1)', margin: 'auto' }}
            textAlign={isMobile ? 'start' : 'center'}
          >
            {' '}
            User Info
          </Text>
          <InfoGruop>
            <Item>
              <div
                style={{
                  display: 'flex',
                  gap: '10px',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <Label>TotalLock</Label>
                <Flex gap="10px" alignItems="center">
                  <InfoItem>
                    <div style={{ display: 'flex', gap: '10px' }}>
                      <Value>
                        <img src="/images/core/ustdIcon.svg" alt="" />
                        <CountUp
                          separator=","
                          start={0}
                          preserveValue
                          delay={0}
                          end={userInfos.userTotalLockUSD}
                          decimals={3}
                          duration={1}
                        />
                        USDT
                      </Value>
                    </div>
                  </InfoItem>
                  {/* ~
                  <InfoItem>
                    {' '}
                    <ValueCore>
                      <img src="/images/core/coreIcon.svg" alt="" />
                      <CountUp
                        separator=","
                        start={0}
                        preserveValue
                        delay={0}
                        end={userInfos.userTotalLock}
                        decimals={3}
                        duration={1}
                      />{' '}
                      CORE
                    </ValueCore>
                  </InfoItem> */}
                </Flex>
                <Label>MaxOut</Label>
                <Flex gap="10px" alignItems="center">
                  <InfoItem>
                    <div style={{ display: 'flex', gap: '10px' }}>
                      <Value>
                        <img src="/images/core/ustdIcon.svg" alt="" />
                        <CountUp
                          separator=","
                          start={0}
                          preserveValue
                          delay={0}
                          end={userInfos.maxOutUSD}
                          decimals={3}
                          duration={1}
                        />
                        USDT
                      </Value>
                    </div>
                  </InfoItem>
                  {/* ~{' '}
                  <InfoItem>
                    <ValueCore>
                      <img src="/images/core/coreIcon.svg" alt="" />
                      <CountUp
                        separator=","
                        start={0}
                        preserveValue
                        delay={0}
                        end={userInfos.usermaxOut}
                        decimals={3}
                        duration={1}
                      />{' '}
                      CORE
                    </ValueCore>
                  </InfoItem> */}
                </Flex>
                <Label>TotalComm</Label>
                <Flex alignItems="center" gap="10px">
                  <InfoItem>
                    <div style={{ display: 'flex', gap: '10px' }}>
                      <Value>
                        <img src="/images/core/ustdIcon.svg" alt="" />
                        <CountUp
                          separator=","
                          start={0}
                          preserveValue
                          delay={0}
                          end={(userInfos.commAvailable + userInfos.commClaimed) * userInfos.corePrice}
                          decimals={3}
                          duration={1}
                        />
                        USDT
                      </Value>
                    </div>
                  </InfoItem>
                  {/* ~
                  <InfoItem>
                    <ValueCore>
                      <img src="/images/core/coreIcon.svg" alt="" />
                      <CountUp
                        separator=","
                        start={0}
                        preserveValue
                        delay={0}
                        end={userInfos.commAvailable + userInfos.commClaimed}
                        decimals={3}
                        duration={1}
                      />{' '}
                      CORE
                    </ValueCore>
                  </InfoItem> */}
                </Flex>
              </div>
              <div
                style={{
                  display: 'flex',
                  gap: '10px',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <Label>CommClaimed</Label>
                <Flex alignItems="center" gap="10px">
                  <InfoItem>
                    <div style={{ display: 'flex', gap: '10px' }}>
                      <Value>
                        <img src="/images/core/ustdIcon.svg" alt="" />
                        <CountUp
                          separator=","
                          start={0}
                          preserveValue
                          delay={0}
                          end={userInfos.commClaimed * userInfos.corePrice}
                          decimals={3}
                          duration={1}
                        />
                        USDT
                      </Value>
                    </div>
                  </InfoItem>
                  {/* ~{' '}
                  <InfoItem>
                    <ValueCore>
                      <img src="/images/core/coreIcon.svg" alt="" />
                      <CountUp
                        separator=","
                        start={0}
                        preserveValue
                        delay={0}
                        end={userInfos.commClaimed}
                        decimals={3}
                        duration={1}
                      />{' '}
                      CORE
                    </ValueCore>
                  </InfoItem> */}
                </Flex>
                <Label>CommAvailable</Label>
                <Flex alignItems="center" gap="10px">
                  <InfoItem>
                    <div style={{ display: 'flex', gap: '10px' }}>
                      <Value>
                        <img src="/images/core/ustdIcon.svg" alt="" />
                        <CountUp
                          separator=","
                          start={0}
                          preserveValue
                          delay={0}
                          end={userInfos.commAvailable * userInfos.corePrice}
                          decimals={3}
                          duration={1}
                        />
                        USDT
                      </Value>
                    </div>
                  </InfoItem>
                  {/* ~{' '}
                  <InfoItem>
                    <ValueCore>
                      <img src="/images/core/coreIcon.svg" alt="" />
                      <CountUp
                        separator=","
                        start={0}
                        preserveValue
                        delay={0}
                        end={userInfos.commAvailable}
                        decimals={3}
                        duration={1}
                      />{' '}
                      CORE
                    </ValueCore>
                  </InfoItem> */}
                </Flex>
              </div>
            </Item>
            <Stack
              w={isMobile ? '100%' : isTablet ? '100%' : isTabletOrDesktop ? '100%' : '50%'}
              p="90px"
              borderRadius="8px"
              border="2px solid transparent"
              background="#1d1617"
              alignItems="center"
              sx={{
                backgroundImage: 'linear-gradient(#1d1617, #1d1617), linear-gradient(180deg, #ff9400 0%, #d97059 100%)',
                backgroundOrigin: 'border-box',
                backgroundClip: 'padding-box, border-box',
              }}
            >
              <div
                style={{
                  width: '40%',
                }}
              >
                {/* <Text fontSize={16} fontWeight={700} marginBottom={20}>
                  Commission Claimed / MaxOut
                </Text> */}
                <CircularProgressbar
                  value={percentage}
                  text={`${percentage.toFixed(2)}%`}
                  strokeWidth={5}
                  styles={buildStyles({
                    pathColor: '#FF9500',
                    textColor: '#fff',
                    trailColor: '#fff',
                    textSize: '25px',
                  })}
                />
              </div>
            </Stack>
          </InfoGruop>
          {/* <div style={{ width: '100%' }}>
            <Label textAlign="right">Total commission</Label>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                width: '100%',
                marginTop: '12px',
              }}
            >
              <Text fontSize="48px" fontWeight="600" lineHeight="60px" letterSpacing="-0.96px">
                <CountUp
                  separator=","
                  start={0}
                  preserveValue
                  delay={0}
                  end={1000}
                  decimals={3}
                  duration={1}
                />
              </Text>
              <div
                style={{
                  background: 'var(--white-white-6, rgba(255, 255, 255, 0.06))',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backdropFilter: 'blur(6px)',
                  borderRadius: '12px',
                  padding: '8px',
                  width: '60px',
                }}
              >
                <img width="40px" height="34px" src="./images/V3/Vector.png" />
              </div>
            </div>
          </div> */}
        </BlockInfo>
        <Stack gap="50px" mt="150px">
          <TitleStep>How to get started</TitleStep>
          <Flex
            direction={isMobile ? 'column' : isTablet ? 'column' : 'row'}
            w="100%"
            alignItems={isMobile ? 'start' : 'center'}
            gap="30px"
          >
            <Stack w={isMobile ? '100%' : isTablet ? '100%' : '50%'} gap="50px">
              <Text fontSize="16px" fontWeight={400} color="rgba(255, 255, 255, 0.7)">
                Reveal to activate your 8% direct commission trading fee rebate voucher:
              </Text>
              <Divider color="rgba(255, 255, 255, 0.15)" w={isMobile ? '100%' : '90%'} />
              <Stack gap="60px">
                {data.map((i, index) => (
                  <>
                    <Divider display={index === 0 ? 'none' : 'flex'} color="rgba(255, 255, 255, 0.15)" w="90%" />
                    <Flex alignItems={isMobile ? 'start' : 'center'} gap="20px">
                      <img src={i.number} alt="" />
                      <img src={i.title} alt="" />
                      <Text fontSize="20px" fontWeight={700} width={isMobile ? '100%' : '400px'}>
                        {i.desc}
                      </Text>
                    </Flex>
                  </>
                ))}
              </Stack>
              <Stack width={isMobile ? '100%' : isTablet ? '100%' : '90%'} alignItems="center" justifyContent="center">
                <ButtonLink>Share now</ButtonLink>
              </Stack>
            </Stack>

            <img width={isMobile ? '100%' : isTablet ? '100%' : '50%'} src="/images/core/bannerRefer2.png" alt="" />
          </Flex>
        </Stack>
      </CardRegister>
    </ReferralPage>
  )
}
export default PageReferral

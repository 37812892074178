import { Link, Text } from '@pancakeswap/uikit'
import { getBlockExploreLink, getBlockExploreName } from 'utils'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useTranslation } from '@pancakeswap/localization'
import truncateHash from '@pancakeswap/utils/truncateHash'

interface DescriptionWithTxProps {
  description?: string
  txHash?: string
}

const DescriptionWithTx: React.FC<React.PropsWithChildren<DescriptionWithTxProps>> = ({ txHash, children }) => {
  const { chainId } = useActiveWeb3React()
  const { t } = useTranslation()

  return (
    <>
      {typeof children === 'string' ? <Text as="p">{children}</Text> : children}
      {txHash && (
        <Link
          style={{ color: '#040810', fontSize: '18px', fontWeight: '400' }}
          external
          href={getBlockExploreLink(txHash, 'transaction', chainId)}
        >
          View on {getBlockExploreName(chainId)}
          <p style={{ color: '#0784C3', fontSize: '18px', fontWeight: '400' }}> : {truncateHash(txHash, 8, 0)}</p>
        </Link>
      )}
    </>
  )
}

export default DescriptionWithTx

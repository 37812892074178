import { Button, Modal, Text, Grid, InjectedModalProps, useToast, Input, useModal } from '@pancakeswap/uikit'
import { useState, useEffect } from 'react'
import { getContract } from 'utils/contractHelpers'
import addresses from 'config/constants/contracts'
import refferalAbi from 'config/abi/refferal.json'
import { useDispatch } from 'react-redux'
import { setRefLink } from 'state/referral'
import { useSigner } from 'wagmi'
import { InjectedProps } from '@pancakeswap/uikit/src/widgets/Modal/types'
import { useWeb3React } from '../../../packages/wagmi/src/useWeb3React'
import styled from 'styled-components'
import { ToastDescriptionWithTx } from 'components/Toast'
import useRegisterConfirmTransaction from 'hooks/useRegisterConfirmTransaction'
import { useTranslation } from '@pancakeswap/localization'
import TrendyPageLoader from 'components/Loader/TrendyPageLoader'
import { ModalVote } from 'components/ModalVote'

interface RegistersModalProps extends InjectedProps {}

const StyledInput = styled(Input)`
  outline: none;
  border-radius: 12px;
  background: #ffffff;
  color: #262130;
  &:focus:not(:disabled) {
    border: none;
  }
  padding: 12px 16px;
`
const StyledButton = styled(Button)`
  background: 'var(--border, linear-gradient(90deg, #E86E00 -1.64%, #F18900 3.29%, #F99F00 9.22%, #FDAC00 14.15%, #FFB100 19.09%, #E86E00 97.07%))';
  &:disabled {
    background: #d0d0d0 !important;
  }
`
const Title = styled(Text)`
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
`
const TextLabel = styled(Text)`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #ffffff;
  text-align: center;
`
const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  .buttonRefer:disabled {
    background: rgba(153, 153, 153, 0.6);
  }
  .buttonRefer {
    background: linear-gradient(90deg, #ffbd33 0%, #ff8000 100%);
  }
`
export const ModalCheckRegister = ({ onCheck, onDismiss }: { onCheck: () => void; onDismiss: () => void }) => {
  const { account, chainId } = useWeb3React()
  const dispatch = useDispatch()
  const { toastSuccess, toastError } = useToast()
  const [loading, setLoading] = useState(false)
  const { search } = window.location
  const query = new URLSearchParams(search)
  const referBy = query.get('ref')
  const baseRefUrl = `${window.location.origin}homepage?ref=`
  const { data: signer } = useSigner()
  const [referByWallet, setReferByWallet] = useState(null)
  const [referCode, setReferCode] = useState('')
  const [myCode, setMyCode] = useState('')
  const [showError, setShowError] = useState(false)
  // const CHAIN_ID = chainId === undefined ? ChainId.BSC_TESTNET : chainId;
  const CHAIN_ID = Number(process.env.NEXT_PUBLIC_DEFAULT_CHAIN)
  const refferCT = getContract({ address: addresses.refferal[CHAIN_ID], abi: refferalAbi, chainId: CHAIN_ID, signer })
  const { t } = useTranslation()
  const saveRef = () => {
    if (referBy) {
      localStorage.setItem('saveAdd', JSON.stringify(referBy))
    }
  }
  const [openModalVote, onDismissVote] = useModal(
    <ModalVote onDismiss={() => onDismiss} />,
    true,
    false,
    'removeModalVote',
  )
  const validateReferByWallet = async (e) => {
    setReferCode(e.target.value)
    const code = e.target.value

    const userInfosByCode = await refferCT.userInfosByCode(code.toLowerCase())
    if (userInfosByCode.user === '0x0000000000000000000000000000000000000000' || code.length < 8) setShowError(true)
    else {
      setShowError(false)
      setReferByWallet(userInfosByCode.user)
    }
  }
  const getRefferCode = async () => {
    if (referBy) {
      const userReferBy = await refferCT.userInfosByCode(referBy.toLowerCase())
      if (userReferBy.user === '0x0000000000000000000000000000000000000000') {
        setShowError(true)
      } else {
        setReferCode(referBy.slice(referBy?.length - 8, referBy?.length).toLocaleLowerCase())
      }
    } else {
      const ref = localStorage.getItem('saveAdd')
      if (JSON.parse(ref)) {
        const userReferByLocal = await refferCT.userInfosByCode(JSON.parse(ref).toLowerCase())
        if (userReferByLocal.user === '0x0000000000000000000000000000000000000000') {
          setShowError(true)
        } else {
          setReferCode(JSON.parse(ref).toLocaleLowerCase())
        }
      }
    }
  }
  const {
    isRegistting,
    isRegisterd,
    isRegisterConfirmed,
    isRegisterConfirming,
    handleRegister,
    handleRegisterConfirm,
  } = useRegisterConfirmTransaction({
    onRequiresRegister: async () => {
      return Promise.resolve(true)
    },
    onRegister: async () => {
      setLoading(true)
      let referByW = referByWallet
      if (!referByW) {
        if (referCode) {
          const userInfosByCode = await refferCT.userInfosByCode(referCode.toLowerCase())
          referByW = userInfosByCode.user
        }
      }

      return refferCT.register(referByW, myCode)
    },
    onRegisterSuccess: async ({ receipt }) => {
      toastSuccess(t('Congratulations'), <ToastDescriptionWithTx txHash={receipt.transactionHash} />)
      setLoading(false)
      onDismiss()
      checkVote()
      onCheck()
    },
    onConfirm: (receipt) => {
      return receipt
    },
    onSuccess: async ({ receipt }) => {
      setLoading(false)
      return Promise.resolve(1)
    },
  })
  const checkVote = () => {
    const isVote = localStorage.getItem('isVotedCore')
    if (!isVote) {
      openModalVote()
    } else if (!JSON.parse(isVote).includes(account)) {
      openModalVote()
    }
  }
  useEffect(() => {
    saveRef()
    getRefferCode()
  }, [account])
  useEffect(() => {
    setMyCode(account.slice(account?.length - 8, account?.length).toLocaleLowerCase())
  }, [account])

  useEffect(() => {
    if (referBy) {
      setReferCode(referBy.toLowerCase())
      const code = referBy

      refferCT.userInfosByCode(code.toLowerCase()).then((userInfosByCode) => {
        if (userInfosByCode.user === '0x0000000000000000000000000000000000000000') setShowError(true)
        else {
          setShowError(false)
          setReferByWallet(userInfosByCode.user)
        }
      })
    }
  }, [])
  return (
    <>
      {' '}
      {loading === true ? (
        <TrendyPageLoader />
      ) : (
        <Modal
          style={{
            width: '90%',
            maxWidth: '434px',
            padding: '32px 18px',
            border: '1px solid var(--border, #E86E00)',
            borderRadius: '20px',
            background: '#402D17',
          }}
          title=""
          onDismiss={onDismiss}
        >
          <ModalContainer>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
              <Title>Register</Title>
              <TextLabel>You don&apos;t have an account yet! Create a new one to start</TextLabel>
            </div>
            <div>
              <Text style={{ color: '#FFFFFF' }} fontSize="16px" fontWeight="700" lineHeight="18px" marginBottom="12px">
                Refer code
              </Text>
              <StyledInput
                value={referCode}
                autoFocus={true}
                onChange={validateReferByWallet}
                placeholder={`Refer code`}
              />
            </div>
            {showError && referCode && <span style={{ color: 'red' }}>Wrong referal code </span>}
            <StyledButton
              style={{
                borderRadius: '12px',
                color: '#FFF',
              }}
              disabled={loading || referCode === '' || showError}
              onClick={handleRegister}
              className="buttonRefer"
            >
              Register Now
            </StyledButton>
          </ModalContainer>
        </Modal>
      )}
    </>
  )
}

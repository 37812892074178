import { FooterLinkType } from '@pancakeswap/uikit'
import { ContextApi } from '@pancakeswap/localization'

export const footerLinks: (t: ContextApi['t']) => FooterLinkType[] = (t) => [
  // {
  //   label: t('Home'),
  //   items: [
  //     {
  //       label: t('Pool'),
  //       href: '/pools',
  //     },
  //     // {
  //     //   label: t('Pools'),
  //     //   href: '/poolv3',
  //     // },
  //     {
  //       label: t('Referral'),
  //       href: '/referral',
  //     },
  //     {
  //       label: t('Tokenomic'),
  //       href: '/tokenomic',
  //     },
  //     {
  //       label: t('Mining'),
  //       href: '/mining',
  //     },
  //     {
  //       label: t('Airdrop'),
  //       href: '/airdrop',
  //     },
  //   ],
  // },
  {
    label: t('Support'),
    items: [
      {
        label: 'CoreStake Whitepaper',
        href: 'https://core-stake.gitbook.io/core-stake/',
      },
      {
        label: 'Core Whitepaper',
        href: 'https://docs.coredao.org/core-white-paper-v1.0.5/satoshi-plus-consensus',
      },
      {
        label: t('FAQ'),
        href: 'https://www.ledger.com/academy/topics/defi/what-is-liquid-staking',
      },
    ],
  },
  {
    label: 'Markets',
    items: [
      {
        label: 'Dextool',
        href: 'https://www.dextools.io/app/en/coredao/pair-explorer/0x92edf9d66581283e8c7502f288b7be37d25d3722',
      },
      {
        label: 'Icream swap',
        href: 'https://icecreamswap.com/swap?chainId=1116&outputCurrency=0xc24B642357D7Dd1bBE33F3D8Aa0101DFA2cf6EB9',
      },
      {
        label: 'Dex Screener',
        href: 'https://dexscreener.com/core/0x92edf9d66581283e8c7502f288b7be37d25d3722',
      },
      {
        label: 'Defined',
        href: 'https://www.defined.fi/core/0x92edf9d66581283e8c7502f288b7be37d25d3722?cache=93b05&quoteToken=token1',
      },
    ],
  },

  // {
  //   label: t('Social JOIN NOW'),
  //   items: [
  //     {
  //       label: 'Support on Polygon',
  //       href: 'https://polygon.technology',
  //       image: '/images/IconPoolsV2.svg',
  //     },
  //     {
  //       label: 'Audit by Certik',
  //       href: '',
  //       image: '/images/chains/certik-logo.png',
  //       border: true,
  //     },
  //     {
  //       label: 'Twitter',
  //       href: '',
  //       image: '/images/V3/Logotwitter.svg',
  //     },

  //     {
  //       label: t('Telegram'),
  //       href: '',
  //       image: '/images/V3/Telegramicon.svg',
  //     },
  //     {
  //       label: t('Global Community'),
  //       href: '',
  //       image: '/images/V3/Telegramicon.svg',
  //     },
  //   ],
  // },
]

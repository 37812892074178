import { useEffect, useState } from 'react'
import { Box, Button, Flex, InjectedModalProps, LinkExternal, Message, Skeleton, Text } from '@pancakeswap/uikit'
import { ChainId } from '@pancakeswap/sdk'
import { FetchStatus } from 'config/constants/types'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useTranslation } from '@pancakeswap/localization'
import useAuth from 'hooks/useAuth'
import useNativeCurrency from 'hooks/useNativeCurrency'
import { useGetCakeBalance } from 'hooks/useTokenBalance'
import { ChainLogo } from 'components/Logo/ChainLogo'

import { getBlockExploreLink, getBlockExploreName } from 'utils'
import { formatBigNumber } from 'utils/formatBalance'
import CopyAddress from './CopyAddress'
import Image from 'next/image'
import { getContract } from 'utils/contractHelpers'
import addresses from 'config/constants/contracts'
import tokenCST from 'config/abi/tokenCore.json'
import { useSigner, useBalance } from 'wagmi'
import { ButtonStake } from 'components/ButtonStake'
import numeral from 'numeral'
interface WalletInfoProps {
  hasLowNativeBalance: boolean
  onDismiss: InjectedModalProps['onDismiss']
}

const WalletInfo: React.FC<WalletInfoProps> = ({ hasLowNativeBalance, onDismiss }) => {
  const { t } = useTranslation()
  const { account, chainId } = useActiveWeb3React()
  const { data: signer } = useSigner()
  const CHAIN_ID = Number(process.env.NEXT_PUBLIC_DEFAULT_CHAIN)
  const { data, isFetched } = useBalance({ addressOrName: account })
  const native = useNativeCurrency()
  const tokenCSTCT = getContract({ address: addresses.tokenCore[CHAIN_ID], abi: tokenCST, chainId: CHAIN_ID, signer })
  const { balance: cakeBalance, fetchStatus: cakeFetchStatus } = useGetCakeBalance()
  const { logout } = useAuth()
  const [tokenBalance, setTokenBalance] = useState('')
  const [shortAccount, setShortAccount] = useState('')
  const getTokenBalance = async () => {
    const balance = await tokenCSTCT.balanceOf(account)
    setTokenBalance(formatBigNumber(balance, 6))
  }
  useEffect(() => {
    if (account) {
      getTokenBalance()
      setShortAccount(`${account.substring(0, 4)}...${account.substring(account.length - 16)}`)
    }
  }, [account])
  const handleLogout = () => {
    onDismiss?.()
    logout()
  }
  const isBSC = native.chainId === ChainId.BSC
  return (
    <>
      <Text color="secondary" fontSize="16px" textTransform="uppercase" fontWeight="bold" mb="8px">
        {t('Your Address')}
      </Text>
      <CopyAddress
        style={{
          background: '#434A57',
          borderRadius: '20px',
          border: '1px dashed #0FD7E6',
          boxShadow: '0px 4px 11px 0px rgba(193, 246, 253, 0.29) inset',
        }}
        account={shortAccount}
        mb="24px"
      />
      {hasLowNativeBalance && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '16px',
            padding: '18px',
            marginBottom: '20px',
            borderRadius: '20px',
            border: '1px solid #FF9500',
            background: '#342E28',
            boxShadow: '0px 4px 11px 0px rgba(193, 246, 253, 0.29) inset',
          }}
        >
          <div>
            <img src="/images/core/iconWarning.svg" />
          </div>
          <Box>
            <Text fontWeight="bold">
              {t('%currency% Balance Low', {
                currency: native.symbol,
              })}
            </Text>
            <Text as="p">
              {t('You need %currency% for transaction fees.', {
                currency: native.symbol,
              })}
            </Text>
          </Box>
        </div>
      )}
      <Flex alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          {!isBSC && <ChainLogo chainId={native.chainId} />}
          <Text ml={isBSC ? 0 : '8px'} style={{ color: '#FFFFFF' }} fontSize="20px" fontWeight="700">
            {native.symbol} {t('Balance')}
          </Text>
        </Flex>
        {isFetched && data && data.value ? (
          <Text style={{ color: '#FFFFFF' }} fontSize="20px" fontWeight="700">
            {formatBigNumber(data.value, 6)}
          </Text>
        ) : (
          <Text style={{ color: '#FFFFFF' }} fontSize="20px" fontWeight="700">
            0
          </Text>
        )}
      </Flex>
      <Flex marginTop={10} alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          <img style={{ width: '24px', height: '24px' }} src="/images/core/IconCore.png" />
          <Text ml={isBSC ? 0 : '8px'} style={{ color: '#FFFFFF' }} fontSize="20px" fontWeight="700">
            CST Balance
          </Text>
        </Flex>
        <Text style={{ color: '#FFFFFF' }} fontSize="20px" fontWeight="700">
          {numeral(tokenBalance).format('0,0')}
        </Text>
      </Flex>
      <Flex alignItems="center" justifyContent="end" mb="24px">
        <LinkExternal color="#FFFFFF" href={getBlockExploreLink(account, 'address', chainId)}>
          {t('View on %site%', {
            site: getBlockExploreName(chainId),
          })}
        </LinkExternal>
      </Flex>
      <ButtonStake style={{ maxWidth: '100%' }} onClick={handleLogout}>
        {t('Disconnect Wallet')}
      </ButtonStake>
    </>
  )
}

export default WalletInfo

import { Button } from '@pancakeswap/uikit'
import { color } from 'configs/color'
import styled from 'styled-components'

export const ButtonStake = styled(Button)`
  width: 100%;
  padding: 5px;
  margin-top: 10px;
  color: ${color.textWhite};
  height: 50px;
  border-radius: 4px;
  background: #ff9400;
  &:disabled {
    background: #d0d0d0 !important;
  }
  .loader {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: rgba(45, 183, 245, 0.45);
    border-right-color: #2db7f5;
    width: 20px;
    height: 20px;
    border-radius: 50%;

    animation: spin 1s linear infinite;
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }
`

import { BrowserRouter as Router, Route, Switch, Link, useRouteMatch, useParams } from 'react-router-dom'
import Referral from 'views/Referral'
import HomePage from 'views/HomePage1'

import NotFound from 'views/NotFound'
import { useEffect } from 'react'
import Mining from 'views/Minning'
import Leaderboard from 'views/Leaderboard'
import PageReview from 'views/Review'
import Vote from 'views/Vote'

export const NewNav = () => {
  return (
    <>
      <div>
        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>

          <Route exact path="/referral">
            <Referral />
          </Route>
          <Route exact path="/mining">
            <Mining />
          </Route>
          <Route exact path="/leaderboard">
            <Leaderboard />
          </Route>
          <Route exact path="/review">
            <PageReview />
          </Route>
          <Route exact path="/vote">
            <Vote />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </div>
    </>
  )
}

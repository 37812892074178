import { Flex, Stack } from '@chakra-ui/react'
import { Text, useMatchBreakpoints } from '@pancakeswap/uikit'
import React from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import CountUp from 'react-countup'
import styled from 'styled-components'

const BlockInfo = styled.div`
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  align-self: stretch;
  border-radius: 16px;
  margin-top: 48px;
  border-radius: 16px;
  @media screen and (max-width: 575px) {
    width: 100%;
  }
`

const InfoItem = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  color: #e6e6e6;
  //max-width: 386px;
  gap: 10px;
  border-radius: 16px;
  background: #2f292a;
  backdrop-filter: blur(6px);
  padding: 16px 24px;
  @media screen and (max-width: 1200px) {
    width: 300px;
  }
  @media screen and (max-width: 575px) {
    width: 100%;
    padding: 16px;
  }
`
const Label = styled(Text)`
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  color: #ffffff;
`
const Value = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  color: #fff;
  @media screen and (max-width: 575px) {
    font-size: 24px;
  }
  @media screen and (max-width: 375px) {
    font-size: 18px;
  }
`
const ValueCore = styled.div`
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  color: #fff;
  display: flex;

  gap: 10px;
  @media screen and (max-width: 575px) {
    font-size: 15px;
  }
  @media screen and (max-width: 375px) {
    font-size: 10px;
  }
`
const InfoGruop = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1024px) {
    width: 100%;
    flex-direction: column;
    gap: 50px;
    align-items: center;
  }
  @media screen and (max-width: 900px) {
    width: 100%;
    flex-direction: column;
    gap: 50px;
    align-items: center;
  }
  @media screen and (max-width: 575px) {
    width: 100%;
    flex-direction: column;
    gap: 50px;
    align-items: center;
  }
`
const Item = styled.div`
  border-radius: 8px;
  padding: 30px;
  border: 2px solid transparent;

  background-image: linear-gradient(#1d1617, #1d1617), linear-gradient(180deg, #ff9400 0%, #d97059 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  display: flex;
  gap: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: 575px) {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    padding: 12px;
  }
`
const BreadcrumbTitle = styled(Text)`
  color: rgba(255, 255, 255, 0.7);
  opacity: 0.5;
  font-family: Poppins;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: 'center';
  @media (max-width: 575px) {
    // padding: 16px;
  }
`

const UserInfo = ({ userInfos }) => {
  const { isMobile, isTablet, isTabletOrDesktop } = useMatchBreakpoints()
  const maxOut = userInfos.maxOutUSD / userInfos.corePrice
  const percentage = userInfos.maxOutUSD > 0 ? (userInfos.commClaimed / maxOut) * 100 : 0
  return (
    <BlockInfo>
      <Text
        fontSize={['48px']}
        fontWeight="600"
        lineHeight="24px"
        style={{ color: 'rgba(255, 255, 255, 1)', margin: 'auto' }}
        textAlign={isMobile ? 'start' : 'center'}
      >
        {' '}
        User Info
      </Text>
      <InfoGruop>
        <Item className="child">
          <div
            style={{
              display: 'flex',
              gap: '10px',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <Label>TotalLock</Label>
            <Flex gap="10px" alignItems="center">
              <InfoItem>
                <div style={{ display: 'flex', gap: '10px' }}>
                  <Value>
                    <img src="/images/core/ustdIcon.svg" alt="" />
                    <CountUp
                      separator=","
                      start={0}
                      preserveValue
                      delay={0}
                      end={userInfos.userTotalLockUSD}
                      decimals={3}
                      duration={1}
                    />
                    USDT
                  </Value>
                </div>
              </InfoItem>
              {/* ~
                  <InfoItem>
                    {' '}
                    <ValueCore>
                      <img src="/images/core/coreIcon.svg" alt="" />
                      <CountUp
                        separator=","
                        start={0}
                        preserveValue
                        delay={0}
                        end={userInfos.userTotalLock}
                        decimals={3}
                        duration={1}
                      />{' '}
                      CORE
                    </ValueCore>
                  </InfoItem> */}
            </Flex>
            <Label>MaxOut</Label>
            <Flex gap="10px" alignItems="center">
              <InfoItem>
                <div style={{ display: 'flex', gap: '10px' }}>
                  <Value>
                    <img src="/images/core/ustdIcon.svg" alt="" />
                    <CountUp
                      separator=","
                      start={0}
                      preserveValue
                      delay={0}
                      end={userInfos.maxOutUSD}
                      decimals={3}
                      duration={1}
                    />
                    USDT
                  </Value>
                </div>
              </InfoItem>
              {/* ~{' '}
                  <InfoItem>
                    <ValueCore>
                      <img src="/images/core/coreIcon.svg" alt="" />
                      <CountUp
                        separator=","
                        start={0}
                        preserveValue
                        delay={0}
                        end={userInfos.usermaxOut}
                        decimals={3}
                        duration={1}
                      />{' '}
                      CORE
                    </ValueCore>
                  </InfoItem> */}
            </Flex>
            <Label>TotalComm</Label>
            <Flex alignItems="center" gap="10px">
              <InfoItem>
                <div style={{ display: 'flex', gap: '10px' }}>
                  <Value>
                    <img src="/images/core/ustdIcon.svg" alt="" />
                    <CountUp
                      separator=","
                      start={0}
                      preserveValue
                      delay={0}
                      end={(userInfos.commAvailable + userInfos.commClaimed) * userInfos.corePrice}
                      decimals={3}
                      duration={1}
                    />
                    USDT
                  </Value>
                </div>
              </InfoItem>
              {/* ~
                  <InfoItem>
                    <ValueCore>
                      <img src="/images/core/coreIcon.svg" alt="" />
                      <CountUp
                        separator=","
                        start={0}
                        preserveValue
                        delay={0}
                        end={userInfos.commAvailable + userInfos.commClaimed}
                        decimals={3}
                        duration={1}
                      />{' '}
                      CORE
                    </ValueCore>
                  </InfoItem> */}
            </Flex>
          </div>
          <div
            style={{
              display: 'flex',
              gap: '10px',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <Label>CommClaimed</Label>
            <Flex alignItems="center" gap="10px">
              <InfoItem>
                <div style={{ display: 'flex', gap: '10px' }}>
                  <Value>
                    <img src="/images/core/ustdIcon.svg" alt="" />
                    <CountUp
                      separator=","
                      start={0}
                      preserveValue
                      delay={0}
                      end={userInfos.commClaimed * userInfos.corePrice}
                      decimals={3}
                      duration={1}
                    />
                    USDT
                  </Value>
                </div>
              </InfoItem>
              {/* ~{' '}
                  <InfoItem>
                    <ValueCore>
                      <img src="/images/core/coreIcon.svg" alt="" />
                      <CountUp
                        separator=","
                        start={0}
                        preserveValue
                        delay={0}
                        end={userInfos.commClaimed}
                        decimals={3}
                        duration={1}
                      />{' '}
                      CORE
                    </ValueCore>
                  </InfoItem> */}
            </Flex>
            <Label>CommAvailable</Label>
            <Flex alignItems="center" gap="10px">
              <InfoItem>
                <div style={{ display: 'flex', gap: '10px' }}>
                  <Value>
                    <img src="/images/core/ustdIcon.svg" alt="" />
                    <CountUp
                      separator=","
                      start={0}
                      preserveValue
                      delay={0}
                      end={userInfos.commAvailable * userInfos.corePrice}
                      decimals={3}
                      duration={1}
                    />
                    USDT
                  </Value>
                </div>
              </InfoItem>
              {/* ~{' '}
                  <InfoItem>
                    <ValueCore>
                      <img src="/images/core/coreIcon.svg" alt="" />
                      <CountUp
                        separator=","
                        start={0}
                        preserveValue
                        delay={0}
                        end={userInfos.commAvailable}
                        decimals={3}
                        duration={1}
                      />{' '}
                      CORE
                    </ValueCore>
                  </InfoItem> */}
            </Flex>
          </div>
        </Item>
        <Stack
          w={isMobile ? '100%' : '500px'}
          p={isMobile ? '40px' : '65px'}
          height="100%"
          borderRadius="8px"
          border="2px solid transparent"
          background="#1d1617"
          alignItems="center"
          sx={{
            backgroundImage: 'linear-gradient(#1d1617, #1d1617), linear-gradient(180deg, #ff9400 0%, #d97059 100%)',
            backgroundOrigin: 'border-box',
            backgroundClip: 'padding-box, border-box',
          }}
          className="child"
        >
          <Text fontSize={24} fontWeight={700}>
            CommClaimed / MaxOut
          </Text>
          <div
            style={{
              width: '50%',
            }}
          >
            {/* <Text fontSize={16} fontWeight={700} marginBottom={20}>
                  Commission Claimed / MaxOut
                </Text> */}
            <CircularProgressbar
              value={percentage}
              text={`${percentage.toFixed(2)}%`}
              strokeWidth={5}
              styles={buildStyles({
                pathColor: '#FF9500',
                textColor: '#fff',
                trailColor: '#fff',
                textSize: '25px',
              })}
            />
          </div>
        </Stack>
      </InfoGruop>
    </BlockInfo>
  )
}
export default UserInfo

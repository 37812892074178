import { formatEther, parseEther } from '@ethersproject/units'
import numeral from 'numeral'
import { Button, Input, Text, useMatchBreakpoints } from '@pancakeswap/uikit'
import poolAbi from 'config/abi/pools.json'
import refferalAbi from 'config/abi/refferal.json'
import addresses from 'config/constants/contracts'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { getContract } from 'utils/contractHelpers'
import { useSigner } from 'wagmi'
import Breadcrumb from 'components/Breadcrumb'
import { Stack, Flex, Divider } from '@chakra-ui/react'
import { ThreeDots } from 'components/Loader/TextLoading'
import PoolRanks from './components/rank'
import poolV2Abi from 'config/abi/poolsV2.json'
import readCoreAbi from 'config/abi/readCoreAbi.json'
import UserInfo from './components/userInfo'
import { ethersToBigNumber } from '../../utils/bigNumber'
const Wrapper = styled.div`
  width: 100%;
  margin: 150px 0;
  @media screen and (max-width: 900px) {
    padding: 20px;
  }
  @media screen and (max-width: 575px) {
    padding: 20px;
  }
`
const Title = styled(Text)`
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px; /* 125% */
  letter-spacing: -0.96px;
  color: white;
  margin: 0 auto 30px auto;
  display: flex;
  max-width: 1204px;

  @media (max-width: 575px) {
    padding: 16px;
  }
`
const BreadcrumbTitle = styled(Text)`
  color: rgba(255, 255, 255, 0.7);
  opacity: 0.5;
  font-family: Poppins;
  margin: 0 auto;
  display: flex;
  max-width: 1204px;

  @media (max-width: 575px) {
    padding: 16px;
  }
`
const StyledInputSearch = styled(Input)`
  outline: none;
  height: 48px;
  width: 600px;
  border-radius: 12px;
  background: var(--greyscale-grayscale-4, #2d2c33);
  padding: 12px 16px;

  &:focus:not(:disabled) {
    border: none;
  }
  ::placeholder {
    color: #67666e;
  }
  @media screen and (max-width: 575px) {
    width: 100%;
  }
`
const ButtonSearch = styled(Button)`
  width: 200px;
  border-radius: 16px;
  border: 1px solid #ff8000;
  background: #ff9400;
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`
const Leaderboard = () => {
  const { account, chainId } = useActiveWeb3React()
  // account = '0x60DCd73e48d778dc554D5Da1Bcc0CB55198beA58'
  const [inputValue, setInputValue] = useState('')
  const [inputValueTotalLock, setInputValueTotalLock] = useState('')
  const [arrayTotalLock, setArrayTotalLock] = useState([])
  const [showRank, setShowRank] = useState(false)
  const { isMobile } = useMatchBreakpoints()
  const [showError, setShowError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [totalLockUSD, setTotalLockUSD] = useState(0)
  const { data: signer } = useSigner()
  const CHAIN_ID = Number(process.env.NEXT_PUBLIC_DEFAULT_CHAIN)
  const refferCT = getContract({ address: addresses.refferal[CHAIN_ID], abi: refferalAbi, chainId: CHAIN_ID, signer })
  const [userInfos, setUserInfo] = React.useState({
    userTotalLock: 0,
    maxOutUSD: 0,
    usermaxOut: 0,
    commClaimed: 0,
    commAvailable: 0,
    corePrice: 0,
    userTotalLockUSD: 0,
  })
  const poolsCT = getContract({
    address: addresses.pools[CHAIN_ID],
    abi: poolAbi,
    chainId: CHAIN_ID,
    signer,
  })
  const getPoolsContractV2 = getContract({
    address: addresses.poolsV2[CHAIN_ID],
    abi: poolV2Abi,
    chainId: CHAIN_ID,
    signer,
  })
  const readCore = getContract({
    address: addresses.readCode[CHAIN_ID],
    abi: readCoreAbi,
    chainId: CHAIN_ID,
    signer,
  })
  const [userRank, setUserRank] = useState({
    direct: 0,
    volumnOnTree: 0,
  })
  const getData = async (address) => {
    try {
      setIsLoading(true)
      await clear()
      const [users, getVolume, getVolume2] = await Promise.all([
        refferCT.userInfos(address),
        poolsCT.getVolumeOnTre(address),
        getPoolsContractV2.getVolumeOnTre(address),
      ])
      const infos = await Promise.all([
        poolsCT.getUserTotalLock(address),
        poolsCT.usermaxOutUSD(address),
        poolsCT.totalComms(address),
      ]).then(async (rs) => {
        const rs2 = await Promise.all([
          poolsCT.remainComm(address),
          poolsCT.CORE2USDT(),
          poolsCT.usermaxOut(address),
          poolsCT.userTotalLockUSD(address),
        ])
        return rs.concat(rs2)
      })
      const infos2 = await Promise.all([
        getPoolsContractV2.getUserTotalLock(address),
        getPoolsContractV2.usermaxOutUSD(address),
        getPoolsContractV2.totalComms(address),
      ]).then(async (rs) => {
        const rs2 = await Promise.all([
          getPoolsContractV2.remainComm(address),
          getPoolsContractV2.usermaxOut(address),
          getPoolsContractV2.userTotalLockUSD(address),
        ])
        return rs.concat(rs2)
      })
      const user = {
        userTotalLock: Number(formatEther(infos[0])) + Number(formatEther(infos2[0])),
        maxOutUSD: Number(formatEther(infos[1])) + Number(formatEther(infos2[1])),
        commClaimed: Number(formatEther(infos[2])) + Number(formatEther(infos2[2])),
        commAvailable: Number(formatEther(infos[3])) + Number(formatEther(infos2[3])),
        corePrice: Number(formatEther(infos[4])),
        usermaxOut: Number(formatEther(infos[5])) + Number(formatEther(infos2[4])),
        userTotalLockUSD: Number(formatEther(infos[6])) + Number(formatEther(infos2[5])),
      }
      setUserInfo(user)
      setUserRank({
        direct: users.totalRefer.toString(),
        volumnOnTree: Number(formatEther(getVolume.add(getVolume2))),
      })
      const dataUsers = await refferCT.getTotalUserByUp(address, users.totalRefer.toString(), 0)
      if (dataUsers.list.length > 0) {
        const arr = dataUsers.list.map((item) => item.user)
        const totalLockUSD = await Promise.all(arr.map((items) => poolsCT.userTotalLockUSD(items)))
        setArrayTotalLock(totalLockUSD)
      }
      setShowRank(true)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }
  const handleChange = (e) => {
    setInputValue(e)
    if (e.length !== 42) {
      setShowError(true)
    } else {
      setShowError(false)
    }
  }
  const getTotalLockOnBranch1 = async (address, j, total = parseEther('0')) => {
    const [coreRs] = await Promise.all([readCore.getTotalLockOnBranch(address)])
    total = total.add(coreRs._usd)
    console.log(Number(formatEther(total)).toFixed(2), address, j, Number(formatEther(coreRs._usd)).toFixed(2))

    if (coreRs.downlines.length > 0) {
      for (let i = 0; i < coreRs.downlines.length; i++) {
        // if(j<3) {
        // eslint-disable-next-line no-await-in-loop
        total = await getTotalLockOnBranch1(coreRs.downlines[i], j + 1, total)
        // }
      }
    }
    return total
  }

  const getTotalLockDownline = async (address) => {
    // console.log(Number(formatEther(total)).toFixed(2))
    const [coreRs] = await Promise.all([readCore.getTotalLockDownline(address)])
    let total = coreRs._usd
    // console.log(address, Number(formatEther(total)).toFixed(2))
    if (coreRs.downlines.length > 0) {
      for (let i = 0; i < coreRs.downlines.length; i++) {
        // eslint-disable-next-line no-await-in-loop
        const t = await getTotalLockDownline(coreRs.downlines[i])

        total = total.add(t)
      }
    }
    return total
  }

  const getTotalLockOnBranch = async (address, total = parseEther('0')) => {
    const [userRs, coreRs] = await Promise.all([
      readCore.userTotalLockUSD(address),
      readCore.getTotalLockDownline(address),
    ])
    total = userRs.add(coreRs._usd)
    // console.log(total.toString())
    if (coreRs.downlines.length > 0) {
      for (let i = 0; i < coreRs.downlines.length; i++) {
        // eslint-disable-next-line no-await-in-loop
        const t = await getTotalLockDownline(coreRs.downlines[i])

        total = total.add(t)
        setTotalLockUSD(Number(formatEther(total)))
      }
    }
    return total
  }
  const getDataTotalLock = async (address) => {
    setIsLoading(true)
    const coreRs = await getTotalLockOnBranch(address)
    setTotalLockUSD(Number(formatEther(coreRs)))
    setIsLoading(false)
    // console.log(coreRs.toString())
  }
  const handleChangeTotalLock = (e) => {
    setInputValueTotalLock(e)
    if (e.length !== 42) {
      setShowError(true)
    } else {
      setShowError(false)
    }
  }
  const clear = () => {
    setArrayTotalLock([])
    setUserRank({
      direct: 0,
      volumnOnTree: 0,
    })
    setUserInfo({
      userTotalLock: 0,
      maxOutUSD: 0,
      usermaxOut: 0,
      commClaimed: 0,
      commAvailable: 0,
      corePrice: 0,
      userTotalLockUSD: 0,
    })
  }
  const checkAdmin = () => {
    const admin = process.env.NEXT_PUBLIC_DEFAULT_ACCOUNT_ADMIN
    if (account && !admin.includes(account)) {
      window.location.replace(window.location.origin)
    }
  }
  // useEffect(() => {
  //   getTotalLockOnBranch1('0xd27eAB4BaCcb63283245a2A6c8987f91ECF40cdA', 0).then(rs => console.log(rs.toString()))
  // },[])
  useEffect(() => {
    checkAdmin()
  }, [account])
  return (
    <Wrapper style={{ height: showRank === true ? 'auto' : '100vh' }}>
      <Stack>
        <Title>Search User</Title>
      </Stack>
      <div
        style={{
          display: 'flex',
          gap: isMobile ? '20px' : '50px',
          flexDirection: isMobile ? 'column' : 'row',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <StyledInputSearch value={inputValue} onChange={(e) => handleChange(e.target.value)} />
        <ButtonSearch disabled={showError || inputValue === ''} onClick={() => getData(inputValue)}>
          Search
        </ButtonSearch>
      </div>
      <div
        style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center', marginTop: '20px' }}
      >
        {showError === true && <Text style={{ color: 'red', fontSize: '24px' }}>This address is not valid</Text>}
      </div>

      {isLoading === true ? (
        <ThreeDots
          style={{
            color: '#FFF',
            fontSize: '36px',
            padding: '10px',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'center',
          }}
          className="loading"
        >
          Loading<span>.</span>
          <span>.</span>
          <span>.</span>
        </ThreeDots>
      ) : (
        <>
          {showRank === true && (
            <>
              <UserInfo userInfos={userInfos} />
              <PoolRanks userRank={userRank} arrayTotalLock={arrayTotalLock} />
            </>
          )}
        </>
      )}
      <Stack>
        <Title>Search Total Lock USD</Title>
      </Stack>
      <div
        style={{
          display: 'flex',
          gap: isMobile ? '20px' : '50px',
          flexDirection: isMobile ? 'column' : 'row',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <StyledInputSearch value={inputValueTotalLock} onChange={(e) => handleChangeTotalLock(e.target.value)} />
        <ButtonSearch
          disabled={showError || inputValueTotalLock === ''}
          onClick={() => getDataTotalLock(inputValueTotalLock)}
        >
          Search
        </ButtonSearch>
        <h1 style={{ color: '#fff', fontSize: 30 }}>{numeral(totalLockUSD).format('0,0.0')}</h1>
      </div>
    </Wrapper>
  )
}
export default Leaderboard

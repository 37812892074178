export const trendyColors = {
  MAIN_GREEN: '#48E3E3',
  LIGHT_GREEN: '#71F7D4',
  ORANGE: '#FEA880',
  PURPLE: '#A8B2FF',
  DARK_PURPLE: '#7645d9',
  GRAY: '#D9D9D9',
  DARK_GRAY: '#393939',
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  MAIN_GREEN_DISABLE: '#48E3E3',
  LIGHT_GREEN_DISABLE: '#A4E3E3',
  ORANGE_DISABLE: '#FCE0D3',
  PURPLE_DISABLE: '#BFC4E8',
  GRAY_DISABLE: '#F4F4F4',
  DARK_GRAY_DISABLE: '#393939',
  WHITE_DISABLE: '#ECECEC',
  PURPLE_NEW: 'var(--primary-primary-1, #8544F5)',
  COLORTABLE: '#777E90',
}

import { Button, ButtonProps } from '@pancakeswap/uikit'
import { useWallet } from 'hooks/useWallet'
import styled from 'styled-components'
// @ts-ignore
// eslint-disable-next-line import/extensions
import { useActiveHandle } from 'hooks/useEagerConnect.bmp.ts'
import Trans from './Trans'

const StyledButton = styled(Button)`
  * {
    font-family: Poppins, sans-serif;
    font-size: 14px;
  }
  border-radius: 4px;
  border: 1px solid #ff9400;
  background: linear-gradient(180deg, #01070d 0%, #ff9400 300%);
  /* light effect/boxShadow */
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  padding: 5px 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-8, 8px);
  color: #ff9400;
  height: 46px;
`

const ConnectWalletButton = ({ children, ...props }: ButtonProps) => {
  const handleActive = useActiveHandle()
  const { onPresentConnectModal } = useWallet()

  const handleClick = () => {
    handleActive()
    // console.log(typeof __NEZHA_BRIDGE__)
    if (typeof __NEZHA_BRIDGE__ !== 'undefined') {
      handleActive()
    } else {
      onPresentConnectModal()
    }
  }

  return (
    <StyledButton onClick={handleClick} {...props}>
      <div style={{ position: 'absolute', width: '3px', height: '18px', background: '#FF9400', left: '0' }} />

      {children || <Trans>Connect Wallet</Trans>}
    </StyledButton>
  )
}

export default ConnectWalletButton

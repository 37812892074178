import { Flex, Stack, Text } from '@chakra-ui/react'
import { useMatchBreakpoints } from '@pancakeswap/uikit'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 50px;
  z-index: 2;
  background-image: url('/images/core/section5.png');
  background-size: contain;
  background-position: center;
  @media (max-width: 900px) {
    background-size: cover;
  }
`

const Section5 = () => {
  const { isMobile, isTablet } = useMatchBreakpoints()

  return (
    <Wrapper>
      <Stack gap="50px" alignItems="center">
        <Text
          color="#FFF"
          textAlign="center"
          fontFamily="Poppins,sans-serif"
          fontSize="48px"
          fontStyle="normal"
          fontWeight={300}
          lineHeight="130%"
        >
          Our
          <span
            style={{
              marginLeft: '10px',
              color: '#FFF',
              fontFamily: 'Poppins,sans-serif',
              fontSize: '48px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '130%',
            }}
          >
            Roadmap
          </span>
        </Text>

        <Stack justifyContent="center" alignItems="center">
          <Stack display={isMobile ? 'flex' : isTablet ? 'flex' : 'none'}>
            <svg xmlns="http://www.w3.org/2000/svg" width="295" height="6" viewBox="0 0 295 6" fill="none">
              <path
                d="M0.113249 3L3 5.88675L5.88675 3L3 0.113249L0.113249 3ZM294.887 3L292 0.113249L289.113 3L292 5.88675L294.887 3ZM3 3.5H292V2.5H3V3.5Z"
                fill="#FF9400"
              />
            </svg>
          </Stack>

          <Text
            color="#FF9400"
            textAlign="center"
            fontFamily="Poppins,sans-serif"
            fontSize="120px"
            fontStyle="normal"
            fontWeight={700}
            lineHeight="130%"
          >
            2023
          </Text>
          <Stack justifyContent="center" alignItems="center">
            <svg xmlns="http://www.w3.org/2000/svg" width="295" height="6" viewBox="0 0 295 6" fill="none">
              <path
                d="M0.113249 3L3 5.88675L5.88675 3L3 0.113249L0.113249 3ZM294.887 3L292 0.113249L289.113 3L292 5.88675L294.887 3ZM3 3.5H292V2.5H3V3.5Z"
                fill="#FF9400"
              />
            </svg>
            <Flex
              gap="30px"
              alignItems="center"
              direction={isMobile ? 'column' : isTablet ? 'column' : 'row'}
              p={isMobile ? '20px 0' : isTablet ? '20px 0' : 0}
            >
              <Flex
                w={isMobile ? '350px' : '435px'}
                h="129px"
                p="24px"
                alignItems="center"
                justifyContent="flex-end"
                gap="24px"
                flexShrink={0}
                borderRadius="8px"
                bg="#2F292A"
                direction={isMobile ? 'row-reverse' : isTablet ? 'row-reverse' : 'row'}
              >
                <Stack>
                  <Text
                    color="#FF9400"
                    textAlign={isMobile ? 'left' : isTablet ? 'left' : 'right'}
                    fontFamily="Poppins,sans-serif"
                    fontSize="18px"
                    fontStyle="normal"
                    fontWeight={700}
                    lineHeight="140%"
                  >
                    The 3rd quarter of 2023
                  </Text>
                  <Text
                    color="#fff"
                    textAlign={isMobile ? 'left' : isTablet ? 'left' : 'right'}
                    fontFamily="Poppins,sans-serif"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight={400}
                    lineHeight="24px"
                  >
                    Launch the Decentralized solution Stake Core.
                  </Text>
                </Stack>
                <img src="/images/core/solutionFrame.svg" />
              </Flex>
              <Stack
                display={isMobile ? 'none' : isTablet ? 'none' : 'flex'}
                width="40px"
                height="249px"
                flexShrink={0}
                background="linear-gradient(0deg, rgba(255, 148, 0, 0.00) 0%, rgba(255, 148, 0, 0.62) 100%)"
              />
              <Flex
                justifyContent="flex-start"
                w={isMobile ? '350px' : '435px'}
                h="129px"
                p="24px"
                alignItems="center"
                gap="24px"
                borderRadius="8px"
                bg="#2F292A"
              >
                <img src="/images/core/userFrame.svg" />

                <Stack>
                  <Text
                    color="#FF9400"
                    textAlign="left"
                    fontFamily="Poppins,sans-serif"
                    fontSize="18px"
                    fontStyle="normal"
                    fontWeight={700}
                    lineHeight="140%"
                  >
                    The 4th quarter of 2023
                  </Text>
                  <Text
                    color="#fff"
                    textAlign="left"
                    fontFamily="Poppins,sans-serif"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight={400}
                    lineHeight="24px"
                  >
                    Launch and airdrop $CST Tokens to distributors and investors.
                  </Text>
                </Stack>
              </Flex>
            </Flex>
            <svg xmlns="http://www.w3.org/2000/svg" width="295" height="6" viewBox="0 0 295 6" fill="none">
              <path
                d="M0.113249 3L3 5.88675L5.88675 3L3 0.113249L0.113249 3ZM294.887 3L292 0.113249L289.113 3L292 5.88675L294.887 3ZM3 3.5H292V2.5H3V3.5Z"
                fill="#FF9400"
              />
            </svg>{' '}
            <Text
              color="#FF9400"
              textAlign="center"
              fontFamily="Poppins,sans-serif"
              fontSize="120px"
              fontStyle="normal"
              fontWeight={700}
              lineHeight="130%"
            >
              2024
            </Text>
            <svg xmlns="http://www.w3.org/2000/svg" width="295" height="6" viewBox="0 0 295 6" fill="none">
              <path
                d="M0.113249 3L3 5.88675L5.88675 3L3 0.113249L0.113249 3ZM294.887 3L292 0.113249L289.113 3L292 5.88675L294.887 3ZM3 3.5H292V2.5H3V3.5Z"
                fill="#FF9400"
              />
            </svg>
            <Flex
              gap="30px"
              alignItems="center"
              direction={isMobile ? 'column' : isTablet ? 'column' : 'row'}
              p={isMobile ? '20px 0' : isTablet ? '20px 0' : 0}
            >
              <Stack gap="30px">
                <Flex
                  w={isMobile ? '350px' : '435px'}
                  h="129px"
                  p="24px"
                  alignItems="center"
                  justifyContent="flex-end"
                  gap="24px"
                  flexShrink={0}
                  borderRadius="8px"
                  bg="#2F292A"
                  direction={isMobile ? 'row-reverse' : isTablet ? 'row-reverse' : 'row'}
                >
                  <Stack>
                    <Text
                      color="#FF9400"
                      textAlign={isMobile ? 'left' : isTablet ? 'left' : 'right'}
                      fontFamily="Poppins,sans-serif"
                      fontSize="18px"
                      fontStyle="normal"
                      fontWeight={700}
                      lineHeight="140%"
                    >
                      The 1st quarter of 2024
                    </Text>
                    <Text
                      color="#fff"
                      textAlign={isMobile ? 'left' : isTablet ? 'left' : 'right'}
                      fontFamily="Poppins,sans-serif"
                      fontSize="16px"
                      fontStyle="normal"
                      fontWeight={400}
                      lineHeight="24px"
                    >
                      Promote the development of Dapps on the core blockchain.
                    </Text>
                  </Stack>
                  <img src="/images/core/2024Frame.svg" />
                </Flex>
                <Flex
                  w={isMobile ? '350px' : '435px'}
                  h="129px"
                  p="24px"
                  alignItems="center"
                  justifyContent="flex-end"
                  gap="24px"
                  flexShrink={0}
                  borderRadius="8px"
                  bg="#2F292A"
                  direction={isMobile ? 'row-reverse' : isTablet ? 'row-reverse' : 'row'}
                >
                  <Stack>
                    <Text
                      color="#FF9400"
                      textAlign={isMobile ? 'left' : isTablet ? 'left' : 'right'}
                      fontFamily="Poppins,sans-serif"
                      fontSize="18px"
                      fontStyle="normal"
                      fontWeight={700}
                      lineHeight="140%"
                    >
                      The 3rd quarter of 2024
                    </Text>
                    <Text
                      color="#fff"
                      textAlign={isMobile ? 'left' : isTablet ? 'left' : 'right'}
                      fontFamily="Poppins,sans-serif"
                      fontSize="16px"
                      fontStyle="normal"
                      fontWeight={400}
                      lineHeight="24px"
                    >
                      List $CST Token on top CEX exchanges.
                    </Text>
                  </Stack>
                  <img src="/images/core/2024Frame.svg" />
                </Flex>
              </Stack>
              <Stack
                display={isMobile ? 'none' : isTablet ? 'none' : 'flex'}
                width="40px"
                height="402px"
                flexShrink={0}
                background="linear-gradient(0deg, rgba(255, 148, 0, 0.00) 0%, rgba(255, 148, 0, 0.62) 100%)"
              />
              <Stack gap="30px">
                <Flex
                  justifyContent="flex-start"
                  w={isMobile ? '350px' : '435px'}
                  h="129px"
                  p="24px"
                  alignItems="center"
                  gap="24px"
                  borderRadius="8px"
                  bg="#2F292A"
                >
                  <img src="/images/core/2024Frame.svg" />

                  <Stack>
                    <Text
                      color="#FF9400"
                      textAlign="left"
                      fontFamily="Poppins,sans-serif"
                      fontSize="18px"
                      fontStyle="normal"
                      fontWeight={700}
                      lineHeight="140%"
                    >
                      The 2nd quarter of 2024
                    </Text>
                    <Text
                      color="#fff"
                      textAlign="left"
                      fontFamily="Poppins,sans-serif"
                      fontSize="16px"
                      fontStyle="normal"
                      fontWeight={400}
                      lineHeight="24px"
                    >
                      Establish the Core blockchain as a leading web3 provider.
                    </Text>
                  </Stack>
                </Flex>
                <Flex
                  justifyContent="flex-start"
                  w={isMobile ? '350px' : '435px'}
                  h="129px"
                  p="24px"
                  alignItems="center"
                  gap="24px"
                  borderRadius="8px"
                  bg="#2F292A"
                >
                  <img src="/images/core/2024Frame.svg" />

                  <Stack>
                    <Text
                      color="#FF9400"
                      textAlign="left"
                      fontFamily="Poppins,sans-serif"
                      fontSize="18px"
                      fontStyle="normal"
                      fontWeight={700}
                      lineHeight="140%"
                    >
                      The 4th quarter of 2024
                    </Text>
                    <Text
                      color="#fff"
                      textAlign="left"
                      fontFamily="Poppins,sans-serif"
                      fontSize="16px"
                      fontStyle="normal"
                      fontWeight={400}
                      lineHeight="24px"
                    >
                      Update the premium version through DAO&apos;s voting proposal.
                    </Text>
                  </Stack>
                </Flex>
              </Stack>
            </Flex>
            <svg xmlns="http://www.w3.org/2000/svg" width="295" height="6" viewBox="0 0 295 6" fill="none">
              <path
                d="M0.113249 3L3 5.88675L5.88675 3L3 0.113249L0.113249 3ZM294.887 3L292 0.113249L289.113 3L292 5.88675L294.887 3ZM3 3.5H292V2.5H3V3.5Z"
                fill="#FF9400"
              />
            </svg>{' '}
            <Text
              color="#FF9400"
              textAlign="center"
              fontFamily="Poppins,sans-serif"
              fontSize="120px"
              fontStyle="normal"
              fontWeight={700}
              lineHeight="130%"
            >
              2025
            </Text>
            <svg xmlns="http://www.w3.org/2000/svg" width="295" height="6" viewBox="0 0 295 6" fill="none">
              <path
                d="M0.113249 3L3 5.88675L5.88675 3L3 0.113249L0.113249 3ZM294.887 3L292 0.113249L289.113 3L292 5.88675L294.887 3ZM3 3.5H292V2.5H3V3.5Z"
                fill="#FF9400"
              />
            </svg>
            <Flex
              gap="30px"
              alignItems="center"
              direction={isMobile ? 'column' : isTablet ? 'column' : 'row'}
              p={isTablet ? '20px 0' : 0}
            >
              <Flex
                w={isMobile ? '350px' : '435px'}
                h="129px"
                p="24px"
                alignItems="center"
                justifyContent="flex-end"
                gap="24px"
                flexShrink={0}
                borderRadius="8px"
                bg="#2F292A"
                direction={isMobile ? 'row-reverse' : isTablet ? 'row-reverse' : 'row'}
              >
                <Stack>
                  <Text
                    color="#FF9400"
                    textAlign={isMobile ? 'left' : isTablet ? 'left' : 'right'}
                    fontFamily="Poppins,sans-serif"
                    fontSize="18px"
                    fontStyle="normal"
                    fontWeight={700}
                    lineHeight="140%"
                  >
                    The 1st quarter of 2025
                  </Text>
                  <Text
                    color="#fff"
                    textAlign={isMobile ? 'left' : isTablet ? 'left' : 'right'}
                    fontFamily="Poppins,sans-serif"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight={400}
                    lineHeight="24px"
                  >
                    Expand Core Dapps to allow staking of multiple Tokens in the Core Blockchain Network.
                  </Text>
                </Stack>
                <img src="/images/core/networkFrame.svg" />
              </Flex>
              <Stack
                display={isMobile ? 'none' : isTablet ? 'none' : 'flex'}
                width="40px"
                height="344px"
                flexShrink={0}
                background="linear-gradient(0deg, rgba(255, 148, 0, 0.00) 0%, rgba(255, 148, 0, 0.62) 100%)"
              />
              <Flex
                justifyContent="flex-start"
                w={isMobile ? '350px' : '435px'}
                h="129px"
                p="24px"
                alignItems="center"
                gap="24px"
                borderRadius="8px"
                bg="#2F292A"
              >
                <img src="/images/core/2024Frame.svg" />

                <Stack>
                  <Text
                    color="#FF9400"
                    textAlign="left"
                    fontFamily="Poppins,sans-serif"
                    fontSize="18px"
                    fontStyle="normal"
                    fontWeight={700}
                    lineHeight="140%"
                  >
                    The 2nd quarter of 2025
                  </Text>
                  <Text
                    color="#fff"
                    textAlign="left"
                    fontFamily="Poppins,sans-serif"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight={400}
                    lineHeight="24px"
                  >
                    Officially enable CST Token staking for users.
                  </Text>
                </Stack>
              </Flex>
            </Flex>
          </Stack>
        </Stack>
      </Stack>
    </Wrapper>
  )
}
export default Section5

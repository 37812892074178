import { Heading, LinkExternal, Text, useMatchBreakpoints } from '@pancakeswap/uikit'
import React from 'react'
import CountUp from 'react-countup'
import styled from 'styled-components'
import truncateHash from '@pancakeswap/utils/truncateHash'
import { ThreeDots } from '../untils'
import { color } from 'configs/color'
import numeral from 'numeral'
import { formatEther } from '@ethersproject/units'
import { getBlockExploreLink } from 'utils'
const CardReferral = styled.div`
  max-width: 1220px;
  min-height: 300px;
  width: 100%;
  height: auto;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 16px;
  align-items: start;
  color: #e6e6e6;
  @media screen and (max-width: 900px) {
    padding: 0 16px;
    width: 100%;
  }
  @media screen and (max-width: 575px) {
    padding: 0 16px;
    width: 100%;
  }
`
const FriendsList = styled.div`
  border-radius: 24px;
  border: 4px solid transparent;
  background-image: linear-gradient(#1d1617, #1d1617), linear-gradient(180deg, #ff9400 0%, #d97059 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  box-shadow: 0px 4px 20px 0px rgba(253, 210, 163, 0.6);
  display: flex;
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  align-self: stretch;
  margin-top: 48px;
  @media screen and (max-width: 575px) {
    padding: 40px 16px;
    margin-top: 28px;
  }
`
const CardFriends = styled.div`
  display: flex;
  padding: 16px 10px;
  align-items: center;
  gap: 12px;
  width: 257px;
  @media screen and (max-width: 900px) {
    padding: 12px;
  }
  @media screen and (max-width: 575px) {
    padding: 10px;
  }
`
const StyledAccount = styled(Text)`
  color: #0784c3;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: -0.176px;
`
const CardInfoUser = styled.div`
  width: 100%;
  height: auto;
  padding: 0 16px;
  @media screen and (max-width: 575px) {
    padding: 0 16px;
  }
`

const StyledHead = styled(Heading)`
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 56px; /* 125% */
  letter-spacing: -0.96px;
  color: white;
  text-align: left;
  @media screen and (max-width: 575px) {
    font-size: 24px;
    line-height: 32px;
  }
`

const StyledSubtitle = styled(Text)`
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 24px;
  text-align: center;
  max-width: 800px;
  color: rgba(173, 171, 178, 1);
  @media screen and (max-width: 575px) {
    font-size: 16px;
    margin-top: 16px;
    line-height: 24px;
  }
  .title {
    color: white;
    font-weight: 700;
  }
`
const GroupChangePage = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 10px;
`
const Group = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  border-radius: 25px;
  background: #444;
  width: 100%;
  @media screen and (max-width: 900px) {
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
  }
  @media screen and (max-width: 575px) {
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    display: flex;
    gap: 16px;
  }
`
const StyledItemChild = styled.span`
  font-size: 18 px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #d7d4dd;
  text-align: center;
`
const StyledTotal = styled(Text)`
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  color: #d7d4dd;

  text-align: center;
`
const StyledLinkAccount = styled.a`
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  color: #0784c3;
`
const Table = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 32px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid var(--white-white-8, rgba(255, 255, 255, 0.08));
  background: var(--black-black-5, rgba(0, 0, 0, 0.05));
  tr {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  th {
    text-align: left;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: #d7d4dd;
    width: 200px;
    text-align: center;
    @media screen and (max-width: 575px) {
      text-align: center;
    }
  }
  @media screen and (max-width: 575px) {
    padding: 0px;
  }
`

const ChildItem = styled.tr`
  word-break: break-all;
  width: 100%;
  border-bottom: 2px solid #d7d4dd;
  padding: 10px;
  td {
    text-align: left;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: rgba(255, 255, 255, 1);
    max-width: 300px;
    text-align: center;
  }
  @media screen and (max-width: 575px) {
    td {
      font-size: 14px;
    }
  }
`
const TitleHead = styled(Text)`
  color: #fffefc;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px; /* 120% */
  letter-spacing: -0.88px;
`
const ButtonBack = styled.div`
  color: ${color.textWhite};
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
`
const PageDashboardV2 = ({
  loadingTable,
  acountChild,
  totalItemChild,
  total7Level,
  listChild,
  handleChangeChild,
  handleBack,
  getButtonChangePage,
  userVolumn,
  userLocked,
}) => {
  const { isMobile, isTablet } = useMatchBreakpoints()
  const CHAIN_ID = Number(process.env.NEXT_PUBLIC_DEFAULT_CHAIN)
  const handleBackReferr = () => {
    window.location.href = window.location.origin + window.location.pathname
  }
  return (
    <CardReferral>
      <StyledHead>Check my refer list</StyledHead>

      <FriendsList>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', gap: '30px' }}>
          <ButtonBack onClick={handleBackReferr}>&#8249; Back</ButtonBack>
          <TitleHead>Downline list</TitleHead>
        </div>
        {loadingTable ? (
          <ThreeDots style={{ textAlign: 'center' }} className="loading">
            Loading
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </ThreeDots>
        ) : (
          <>
            <Group>
              <CardFriends style={{ width: isMobile ? '100%' : isTablet ? '100%' : '300px' }}>
                <StyledAccount>F{acountChild.length - 1}:</StyledAccount>

                <StyledLinkAccount rel="noreferrer" target="_blank">
                  {truncateHash(acountChild[acountChild.length - 1], 8, 6)}
                </StyledLinkAccount>
                <LinkExternal
                  fontSize={['14px', '16px', '18px', '20px', '22px']}
                  href={getBlockExploreLink(acountChild[acountChild.length - 1], 'address', CHAIN_ID)}
                  ellipsis={true}
                  style={{ color: 'rgba(249, 249, 249, 1)' }}
                  color="#00F0E1"
                ></LinkExternal>
              </CardFriends>
              <CardFriends style={{ width: isMobile ? '100%' : isTablet ? '100%' : '300px' }}>
                <StyledItemChild>Total of F{acountChild.length}</StyledItemChild>
                <StyledTotal>{totalItemChild}</StyledTotal>
              </CardFriends>
              <CardFriends style={{ width: isMobile ? '100%' : isTablet ? '100%' : '300px' }}>
                <StyledItemChild>Volumn</StyledItemChild>
                <StyledTotal>
                  ${' '}
                  <CountUp separator="," start={0} preserveValue delay={0} end={userVolumn} decimals={2} duration={1} />
                </StyledTotal>
              </CardFriends>
              <CardFriends style={{ width: isMobile ? '100%' : isTablet ? '100%' : '300px' }}>
                <StyledItemChild>Locked</StyledItemChild>
                <StyledTotal>
                  ${' '}
                  <CountUp separator="," start={0} preserveValue delay={0} end={userLocked} decimals={2} duration={1} />
                </StyledTotal>
              </CardFriends>
              <CardFriends style={{ width: isMobile ? '100%' : isTablet ? '100%' : '300px' }}>
                <StyledItemChild>Total refer downline</StyledItemChild>
                <StyledTotal>{total7Level}</StyledTotal>
              </CardFriends>
            </Group>
            <Table>
              <tr>
                <th>Friends</th>

                <th>Volumn</th>
                <th>Locked</th>
              </tr>
              {listChild.map((item, index) => (
                <div key={index}>
                  <ChildItem key={index}>
                    <td>
                      <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                        <div
                          onClick={() => handleChangeChild(item.account)}
                          style={{
                            cursor: 'pointer',
                            color: item.child > 0 ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 1)',
                            display: 'flex',
                            alignItems: 'center',
                            gap: 10,
                            fontSize: isMobile ? '14px' : '24px',
                            fontWeight: '400',
                          }}
                        >
                          <StyledAccount>
                            {item.account.substring(0, 2)}...{item.account.substring(item.account.length - 4)}
                          </StyledAccount>

                          {item.child > 0 && !item.showChild ? (
                            <img src="/images/core/plus.svg" />
                          ) : (
                            <img src="/images/core/square.svg" />
                          )}
                        </div>
                        <LinkExternal
                          fontSize={['14px', '16px', '18px', '20px', '22px']}
                          href={getBlockExploreLink(item.account, 'address', CHAIN_ID)}
                          ellipsis={true}
                          style={{ color: 'rgba(249, 249, 249, 1)' }}
                          color="#00F0E1"
                        ></LinkExternal>
                      </div>
                    </td>

                    <td>
                      ${' '}
                      <CountUp
                        style={{ color: 'rgba(255, 255, 255, 1)', fontSize: isMobile ? '14px' : '24px' }}
                        separator=","
                        start={0}
                        preserveValue
                        delay={0}
                        end={Number(formatEther(item.volume[index].add(item.volume2[index])))}
                        decimals={2}
                        duration={1}
                      />
                    </td>
                    <td>
                      ${' '}
                      <CountUp
                        separator=","
                        start={0}
                        preserveValue
                        delay={0}
                        end={Number(formatEther(item.lockedUSD[index].add(item.lockedUSD2[index])))}
                        decimals={2}
                        duration={1}
                      />
                    </td>
                  </ChildItem>
                  {/* {item.showChild && (
                    <ChildItem key={index + '-' + index}>
                      <td colSpan={3} style={{ padding: 0 }}>
                        <Child referBy={item.account} />
                      </td>
                    </ChildItem>
                  )} */}
                </div>
              ))}
            </Table>
          </>
        )}
        <GroupChangePage>
          {acountChild.length > 1 ? (
            <button
              type="button"
              onClick={handleBack}
              style={{
                cursor: 'pointer',
                color: '#FFF',
                background: 'linear-gradient(90deg, #FFBD33 0%, #FF8000 100%)',
                borderRadius: '20px',
                fontSize: '18px',
                width: '60px',
                height: '28px',
              }}
            >
              Back
            </button>
          ) : null}
          {getButtonChangePage(2)}
        </GroupChangePage>
      </FriendsList>
    </CardReferral>
  )
}
export default PageDashboardV2

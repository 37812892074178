import { useTranslation } from '@pancakeswap/localization'
import { ChainId } from '@pancakeswap/sdk'
import {
  ArrowForwardIcon,
  Button,
  Grid,
  Message,
  MessageText,
  Modal,
  Text,
  useMatchBreakpoints,
} from '@pancakeswap/uikit'
import { FlexGap } from 'components/Layout/Flex'
import { ChainLogo } from 'components/Logo/ChainLogo'
import useAuth from 'hooks/useAuth'
import { useSessionChainId } from 'hooks/useSessionChainId'
import { useSwitchNetwork } from 'hooks/useSwitchNetwork'
import Image from 'next/future/image'
import { Chain, useAccount, useNetwork } from 'wagmi'
import Dots from '../Loader/Dots'
import LogoMobile from 'components/Svg/LogoMobile'

// Where page network is not equal to wallet network
export function WrongNetworkModal({ currentChain, onDismiss }: { currentChain: Chain; onDismiss: () => void }) {
  const { switchNetworkAsync, isLoading, canSwitch } = useSwitchNetwork()
  const { chain } = useNetwork()
  const { logout } = useAuth()
  const { isConnected } = useAccount()
  const [, setSessionChainId] = useSessionChainId()
  const chainId = currentChain.id || ChainId.MATIC
  const { t } = useTranslation()
  const { isMobile } = useMatchBreakpoints()
  const switchText = t('Switch to %network%', { network: currentChain.name })

  return (
    <Modal
      style={{
        width: isMobile ? '100%' : '400px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '20px',
        background: '#402D17',
        border: '1px solid var(--border, #E86E00)',
      }}
      title=""
      onDismiss={onDismiss}
    >
      <Text style={{ color: '#FFFFFF' }} textAlign="center" fontSize="24px" fontWeight="700" margin="20px 0">
        You are in wrong network
      </Text>
      <Grid style={{ gap: '16px', justifyItems: 'center' }} maxWidth="336px">
        <Text style={{ color: '#FFFFFF' }}>
          {t('This page is located for %network%.', { network: currentChain.name })}
        </Text>
        <Text style={{ color: '#FFFFFF' }}>
          {t('You are under network now, please switch the network to continue.', { network: chain?.name ?? '' })}
        </Text>
        <div style={{ textAlign: 'center' }}>
          <img width="160px" height="60px" src="/images/core/Logo.png" alt="" className="mobile-icon" />
        </div>
        <Message variant="warning" icon={false} p="8px 12px">
          <MessageText>
            <FlexGap gap="12px">
              <FlexGap gap="6px">
                <ChainLogo chainId={chain?.id} /> <img src="/images/core/arrow.png" />
                <ChainLogo chainId={chainId} />
              </FlexGap>
              <span style={{ color: '#2D292E', fontSize: '16px' }}>{t('Switch network to continue.')}</span>
            </FlexGap>
          </MessageText>
        </Message>
        {canSwitch && (
          <Button
            style={{
              background:
                'var(--border, linear-gradient(90deg, #E86E00 -1.64%, #F18900 3.29%, #F99F00 9.22%, #FDAC00 14.15%, #FFB100 19.09%, #E86E00 97.07%))',
              color: '#FFFFFF',
              padding: '0 8px',
              width: '300px',
              borderRadius: '12px',
              border: '1px solid #FF9500',
            }}
            isLoading={isLoading}
            onClick={() => switchNetworkAsync(chainId)}
          >
            {isLoading ? <Dots>{switchText}</Dots> : switchText}
          </Button>
        )}
        {isConnected && (
          <Button
            style={{ width: '300px', borderRadius: '12px', color: '#FF9500', border: '1px solid #FF9500' }}
            onClick={() =>
              logout().then(() => {
                setSessionChainId(chainId)
              })
            }
            variant="secondary"
          >
            {t('Disconnect Wallet')}
          </Button>
        )}
      </Grid>
    </Modal>
  )
}

import { Text } from '@pancakeswap/uikit'
import styled from 'styled-components'
import { color } from './color'

export const TextTitle = styled(Text)`
  font-family: Blinker, sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px; /* 125% */
  letter-spacing: -0.96px;
  background: linear-gradient(180deg, #ff8000 0%, #f8a68e 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
`
export const TextTitle02 = styled(Text)`
  color: ${color.textWhite};
  font-family: Blinker, sans-serif;
  font-size: 40px;
  font-style: normal;
  line-height: 18px;
  text-align: center;
  font-weight: 700;
`
export const TextTitle03 = styled(Text)`
  color: ${color.textWhite};
  font-family: 'Blinker-Light', sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: 18px;
  text-align: center;
`

export const Text01 = styled(Text)`
  font-family: Blinker, sans-serif;
  color: ${color.textWhite};
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  @media screen and (max-width: 575px) {
    font-size: 18px;
  }
`
export const Text02 = styled(Text)`
  font-family: Poppins, sans-serif;
  color: white;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  @media screen and (max-width: 575px) {
    font-size: 18px;
  }
`
export const Text03 = styled(Text)`
  font-family: Blinker, sans-serif;
  color: ${color.textWhite};
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media screen and (max-width: 900px) {
    font-size: 18px;
  }
  @media screen and (max-width: 575px) {
    font-size: 16px;
  }
`
export const Text04 = styled(Text)`
  font-family: Blinker, sans-serif;
  color: ${color.textWhite};
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
`

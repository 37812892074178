import styled from 'styled-components'
import { Heading, Text, Flex, Button, useToast, Input, LinkExternal, useMatchBreakpoints } from '@pancakeswap/uikit'
import React, { useState } from 'react'
import 'react-tooltip/dist/react-tooltip.css'
import { getContract } from 'utils/contractHelpers'
import addresses from 'config/constants/contracts'
import refferalAbi from 'config/abi/refferal.json'
import poolAbi from 'config/abi/pools.json'
import { useDispatch } from 'react-redux'
import { setRefLink } from 'state/referral'
import { useSigner } from 'wagmi'
import truncateHash from '@pancakeswap/utils/truncateHash'
import { useWeb3React } from '../../../packages/wagmi/src/useWeb3React'
import TrendyPageLoader from 'components/Loader/TrendyPageLoader'
import { formatEther } from '@ethersproject/units'
import { getBlockExploreLink } from 'utils'
import PageReferral from './components/PageReferral'
import PageDashboardV2 from './components/ReferralDashboardV2'
import { shortenURL } from './untils'
import { usePoolsV2Contract } from '../../hooks/useContract'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 120px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  th {
    border-collapse: collapse;
  }
  th,
  td {
    padding: 10px;
    text-align: center;
  }
  @media screen and (max-width: 900px) {
    flex-direction: column;
    padding: 60px 0;
    width: 100%;
  }
  @media screen and (max-width: 575px) {
    flex-direction: column;
    padding: 80px 0;
    width: 100%;
  }
`

const ButtonChangePage = styled.button`
  background: #fbbf9a;
  border: none;
  outline: none;
  cursor: pointer;
`

export const copyText = (text) => {
  const el = document.createElement('textarea')
  el.value = text
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}
const Referral = () => {
  const getPoolsContractV2 = usePoolsV2Contract()
  const [linkRef, setLinkRef] = React.useState('')
  const [showCopied, setShowCopied] = React.useState(false)
  const { account, chainId } = useWeb3React()
  // account = '0x657aa592FdCa8614c5D5A400f19d099B9f72fb7D'
  const dispatch = useDispatch()
  const { toastSuccess, toastError } = useToast()
  const [loading, setLoading] = React.useState(false)
  const [loadingPage, setLoadingPage] = React.useState(true)
  const [loadingTable, setLoadingTable] = React.useState(true)
  const { search } = window.location
  const query = new URLSearchParams(search)
  const referBy = query.get('ref')
  const baseRefUrl = `${window.location.origin}homepage?ref=`
  const { data: signer } = useSigner()
  const CHAIN_ID = Number(process.env.NEXT_PUBLIC_DEFAULT_CHAIN)
  const refferCT = getContract({ address: addresses.refferal[CHAIN_ID], abi: refferalAbi, chainId: CHAIN_ID, signer })
  const poolsCT = getContract({
    address: addresses.pools[CHAIN_ID],
    abi: poolAbi,
    chainId: CHAIN_ID,
    signer,
  })
  const [userIsRegister, setUserIsRegister] = React.useState(false)
  const [listChild, setListChild] = React.useState([])
  const [listChildV1, setListChildV1] = React.useState([])
  const [topReferral, setTopReferral] = useState([])
  const [countPage, setCountPage] = React.useState(0)
  const [activePage, setActivePage] = React.useState(0)
  const [myCode, setMyCode] = useState('')
  const [referByWallet, setReferByWallet] = useState(referBy)
  const [referCode, setReferCode] = useState('')
  const [showError, setShowError] = useState(true)
  const [userSearch, setUserSearch] = useState('')
  const [resultSearch, setResultSearch] = useState('')
  const [totalItemChild, setTotalItemChild] = React.useState(0)
  const [userVolumnOnTre, setuserVolumnOnTre] = React.useState(0)
  const [userLocked, setUserLocked] = React.useState(0)
  const [acountChild, setAccountChild] = React.useState(() => {
    if (account) {
      return [account]
    }
    return []
  })
  const [total7Level, setTotal7Level] = React.useState(0)
  // const unit = chainId && NATIVE[chainId].symbol
  const [userInfos, setUserInfo] = React.useState({
    userTotalLock: 0,
    maxOutUSD: 0,
    usermaxOut: 0,
    commClaimed: 0,
    commAvailable: 0,
    corePrice: 0,
    userTotalLockUSD: 0,
  })
  const { isMobile } = useMatchBreakpoints()
  const [tab, setTab] = useState(2)
  const getTotalRefferChild = async (page, accountChild) => {
    if (!account) {
      setLoadingPage(true)
    }
    setLoadingPage(false)
    const limit = 5
    const userVolumn = await poolsCT.getVolumeOnTre(accountChild ? accountChild : account)
    const userVolumn2 = await getPoolsContractV2.getVolumeOnTre(accountChild ? accountChild : account)
    const userTotalLock = await poolsCT.userTotalLockUSD(accountChild ? accountChild : account)
    const userTotalLock2 = await getPoolsContractV2.userTotalLockUSD(accountChild ? accountChild : account)
    setuserVolumnOnTre(Number(formatEther(userVolumn.add(userVolumn2))))
    setUserLocked(Number(formatEther(userTotalLock.add(userTotalLock2))))
    const data = await Promise.all([
      refferCT.getTotalUserByUp(accountChild ? accountChild : account, limit, page),
      refferCT.userInfos(!accountChild ? account : accountChild),
    ])
    const countPage = Math.ceil(Number(data[0].totalItem.toString()) / limit)
    const arr = data[0].list.map((item) => item.user)
    const a = data[0][0]
    const volumeOntree = await Promise.all(a.map((x, r) => poolsCT.getVolumeOnTre(a[r].user)))
    const totalLocked = await Promise.all(a.map((x, r) => poolsCT.userTotalLock(a[r].user)))
    const totalLockedUSD = await Promise.all(a.map((x, r) => poolsCT.userTotalLockUSD(a[r].user)))
    const volumeOntree2 = await Promise.all(a.map((x, r) => getPoolsContractV2.getVolumeOnTre(a[r].user)))
    const totalLocked2 = await Promise.all(a.map((x, r) => getPoolsContractV2.userTotalLock(a[r].user)))
    const totalLockedUSD2 = await Promise.all(a.map((x, r) => getPoolsContractV2.userTotalLockUSD(a[r].user)))
    setListChild(
      arr.map((item, i) => {
        return {
          account: item,
          volume: volumeOntree,
          volume2: volumeOntree2,
          locked: totalLocked,
          locked2: totalLocked2,
          child: Number(a[i].totalRefer),
          showChild: false,
          lockedUSD: totalLockedUSD,
          lockedUSD2: totalLockedUSD2,
        }
      }),
    )
    setTotalItemChild(Number(data[0].totalItem.toString()))
    setCountPage(countPage)
    setTotal7Level(data[1].totalRefer10.toString())
    setLoadingTable(false)
  }

  const validateReferByWallet = async (e) => {
    setReferCode(e.target.value)
    const code = e.target.value

    const userInfosByCode = await refferCT.userInfosByCode(code.toLowerCase())
    if (userInfosByCode.user === '0x0000000000000000000000000000000000000000') setShowError(true)
    else {
      setShowError(false)
      setReferByWallet(userInfosByCode.user)
    }
  }

  const handleClickPage = (index) => {
    const limit = 5
    const skip = index * limit
    getTotalRefferChild(skip, acountChild[acountChild.length - 1])
    setActivePage(index)
  }

  const handleChangeChild = (accountB) => {
    // setListChild(
    //   listChild.map((l) => {
    //     if (l.account === accountB) l.showChild = !l.showChild
    //     return l
    //   }),
    // )
    getTotalRefferChild(0, accountB)
    setAccountChild([...acountChild, accountB])
  }

  const handleChangeChildV1 = (accountB) => {
    setListChildV1(
      listChildV1.map((l) => {
        if (l.account.toLowerCase() === accountB.toLowerCase()) l.showChild = !l.showChild
        return l
      }),
    )
  }

  const handleChangePage = (index) => {
    if (typeof Number(index) !== 'number') {
      return
    }
    const limit = 5
    const skip = index * limit
    getTotalRefferChild(skip, acountChild[acountChild.length - 1])
    setActivePage(Number(index))
  }

  const getUserInfo = async () => {
    if (!account) {
      setLoadingPage(true)
    }
    setLoadingPage(false)
    const infos = await Promise.all([
      poolsCT.getUserTotalLock(account),
      poolsCT.usermaxOutUSD(account),
      poolsCT.totalComms(account),
    ]).then(async (rs) => {
      const rs2 = await Promise.all([
        poolsCT.remainComm(account),
        poolsCT.CORE2USDT(),
        poolsCT.usermaxOut(account),
        poolsCT.userTotalLockUSD(account),
      ])
      return rs.concat(rs2)
    })
    const infos2 = await Promise.all([
      getPoolsContractV2.getUserTotalLock(account),
      getPoolsContractV2.usermaxOutUSD(account),
      getPoolsContractV2.totalComms(account),
    ]).then(async (rs) => {
      const rs2 = await Promise.all([
        getPoolsContractV2.remainComm(account),
        getPoolsContractV2.usermaxOut(account),
        getPoolsContractV2.userTotalLockUSD(account),
      ])
      return rs.concat(rs2)
    })
    const user = {
      userTotalLock: Number(formatEther(infos[0])) + Number(formatEther(infos2[0])),
      maxOutUSD: Number(formatEther(infos[1])) + Number(formatEther(infos2[1])),
      commClaimed: Number(formatEther(infos[2])) + Number(formatEther(infos2[2])),
      commAvailable: Number(formatEther(infos[3])) + Number(formatEther(infos2[3])),
      corePrice: Number(formatEther(infos[4])),
      usermaxOut: Number(formatEther(infos[5])) + Number(formatEther(infos2[4])),
      userTotalLockUSD: Number(formatEther(infos[6])) + Number(formatEther(infos2[5])),
    }
    setUserInfo(user)
  }
  const getButtonChangePage = (limitButton) => {
    let arr = []
    const style = { background: '#00f0e1', color: 'black' }
    if (countPage === 1) {
      return null
    }
    if (countPage >= 4) {
      for (let i = 0; i < limitButton; i++) {
        arr.push(
          <ButtonChangePage
            className="btnRefer"
            key={i}
            onClick={() => handleClickPage(i)}
            style={activePage === i ? style : {}}
          >
            {i + 1}
          </ButtonChangePage>,
        )
      }
      arr.push(
        <input key={'a'} type="number" style={{ width: '40px' }} onChange={(e) => handleChangePage(e.target.value)} />,
      )
      arr.push(
        <ButtonChangePage
          className="btnRefer"
          key={countPage}
          style={activePage === countPage - 1 ? style : {}}
          onClick={() => handleClickPage(countPage - 1)}
        >
          {countPage - 1}
        </ButtonChangePage>,
      )
    } else {
      for (let i = 0; i < countPage; i++) {
        arr.push(
          <ButtonChangePage
            className="btnRefer"
            key={i}
            style={activePage === i ? style : {}}
            onClick={() => handleClickPage(i)}
          >
            {i + 1}
          </ButtonChangePage>,
        )
      }
    }
    return arr
  }

  const getData = async () => {
    if (!account) {
      setLoadingPage(true)
    } else {
      setLoadingPage(false)
      const top10Refer = await refferCT.getTop10Refer()
      setTopReferral(top10Refer)
      const isRegister = await refferCT.isReferrer(account)
      setUserIsRegister(isRegister)

      // getRefer()
      if (isRegister && account) {
        setLinkRef(getLinkRef())
      } else {
        setLinkRef('')
      }
      setLoadingPage(false)
    }
  }
  const handleBack = () => {
    const newArr = [...acountChild]
    newArr.pop()
    setAccountChild(newArr)
    getTotalRefferChild(0, newArr[newArr.length - 1])
  }

  React.useEffect(() => {
    getData()
    getUserInfo()
  }, [account, userIsRegister, userInfos])

  React.useEffect(() => {
    if (!account) {
      return
    }
    getTotalRefferChild(0, account)
    setMyCode(account.slice(account.length - 6, account.length).toLocaleLowerCase())
    getRefferCode()
    // getUserInfo()
    if (!acountChild.length && account) {
      setAccountChild([...acountChild, account])
    } else {
      setAccountChild([account])
    }
  }, [account])

  const getLinkRef = () => {
    const param = window.location.origin
    const text = `${param}?ref=${account.slice(account.length - 8, account.length).toLocaleLowerCase()}`
    return text
  }

  const getRefferCode = async () => {
    if (referBy) {
      const userReferBy = await refferCT.userInfosByCode(referBy.toLowerCase())
      if (userReferBy.user === '0x0000000000000000000000000000000000000000') {
        setShowError(true)
      } else {
        setReferCode(referBy.slice(referBy?.length - 8, referBy?.length).toLocaleLowerCase())
      }
    } else {
      const ref = localStorage.getItem('saveAdd')
      if (JSON.parse(ref)) {
        const userReferByLocal = await refferCT.userInfosByCode(JSON.parse(ref).toLowerCase())
        if (userReferByLocal.user === '0x0000000000000000000000000000000000000000') {
          setShowError(true)
        } else {
          setReferCode(JSON.parse(ref).toLocaleLowerCase())
          setShowError(false)
        }
      }
    }
  }

  // const getRefer = async () => {
  //   const pool = await getPoolContract.pools(5)
  //   const interest = Number(Number(pool.commPercent.toString()) * 0.000001 * 100).toFixed(2)
  //   setInterest(Number(interest))
  // }
  const onRegister = async () => {
    try {
      let referByW = referByWallet
      if (!referByW) {
        if (referCode) {
          const userInfosByCode = await refferCT.userInfosByCode(referCode.toLowerCase())
          referByW = userInfosByCode.user
        }
      }
      const txReceipt = await refferCT.register(referByW, myCode)
      if (txReceipt?.hash) {
        dispatch(setRefLink(`${baseRefUrl}${account}`))
        toastSuccess('Congratulations', 'You have successfully registered!')
        setLinkRef(getLinkRef())
        setLoadingPage(true)
        getData()
      } else {
        toastError('Please try again. Confirm the transaction and make sure you are paying enough gas!')
      }
      setLoading(false)
    } catch (error) {
      console.log('onRegister error:', error)
      setLoading(false)
      toastError('Please try again. Confirm the transaction and make sure you are paying enough gas!')
    }
  }

  const handleRef = () => {
    if (userIsRegister) {
      const text = getLinkRef()
      copyText(text)
      setShowCopied(true)
    }
  }

  const handleCode = (text) => {
    copyText(text)
    setShowCopied(true)
  }

  const handleLeave = () => {
    setTimeout(() => {
      setShowCopied(false)
    }, 100)
  }

  const formatLinkRef = (ref, start, end) => {
    if (ref?.includes('0x')) {
      return truncateHash(ref, start, end)
    }
    return linkRef
  }

  const handleSearchUserCode = async () => {
    const userInfosByCode = await refferCT.userInfosByCode(userSearch)
    if (userInfosByCode.user === '0x0000000000000000000000000000000000000000') {
      setResultSearch('Wrong referal code')
      return
    }
    setResultSearch(userInfosByCode.user)
  }

  const showResultSearch = () => {
    if (resultSearch === '') return null
    if (resultSearch === 'Wrong referal code')
      return <p style={{ color: 'red', marginTop: 20, textAlign: 'center' }}>Wrong referal code</p>
    return (
      <Text style={{ color: '#C5C5C5', marginTop: 40 }} ellipsis={true}>
        <LinkExternal
          fontSize={['14px', '16px', '18px', '20px', '22px']}
          href={getBlockExploreLink(resultSearch, 'address', CHAIN_ID)}
          ellipsis={true}
          style={{ color: '#00F0E1' }}
          color="#00F0E1"
        >
          {shortenURL(`${resultSearch}`, 16)}
        </LinkExternal>
      </Text>
    )
  }
  return (
    <>
      {loadingPage ? (
        <TrendyPageLoader />
      ) : (
        <Wrapper>
          {tab === 2 && (
            <PageReferral
              linkRef={linkRef}
              handleRef={handleRef}
              handleLeave={handleLeave}
              userIsRegister={userIsRegister}
              showCopied={showCopied}
              referCode={referCode}
              validateReferByWallet={validateReferByWallet}
              showError={showError}
              onRegister={onRegister}
              userSearch={userSearch}
              setUserSearch={setUserSearch}
              handleSearchUserCode={handleSearchUserCode}
              showResultSearch={showResultSearch}
              setTab={setTab}
              userInfos={userInfos}
            />
          )}
          {tab === 3 && (
            <PageDashboardV2
              loadingTable={loadingTable}
              acountChild={acountChild}
              totalItemChild={totalItemChild}
              total7Level={total7Level}
              listChild={listChild}
              handleChangeChild={handleChangeChild}
              handleBack={handleBack}
              getButtonChangePage={getButtonChangePage}
              userVolumn={userVolumnOnTre}
              userLocked={userLocked}
            />
          )}
        </Wrapper>
      )}
    </>
  )
}

export default Referral

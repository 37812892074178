import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
// import Exchange from './components/Exchange'
import { Flex, Text } from '@chakra-ui/react'
import { formatEther } from '@ethersproject/units'
import { useMatchBreakpoints } from '@pancakeswap/uikit'
import 'aos/dist/aos.css'
import axios from 'axios'
import poolAbi from 'config/abi/pools.json'
import addresses from 'config/constants/contracts'
import CountUp from 'react-countup'
import { getContract } from 'utils/contractHelpers'
import { useSigner } from 'wagmi'
import Section1 from './Section1'
import Section2 from './Section2'
import Section3 from './Section3'
import Section4 from './Section4'
import Section5 from './Section5'

const Wrapper = styled.div`
  width: 100%;
  font-style: normal;
  // background-image: url('/images/core/newHome.png');
  // background-size: 100% 100%;
  // background-position: center;
  height: 100%;
  padding: 80px 0 0 0;
  .imgHome {
    margin-top: 50px;
  }
  .imgPHG {
    position: absolute;
    top: 2%;
    left: 5%;
    z-index: 1;
  }
  // @media screen and (max-width: 1200px) {
  //   padding: 50px 0px;
  // }
  @media screen and (max-width: 900px) {
    .imgPHG {
      width: 170px;
    }
    .imgPHG2 {
      width: 200px;
    }
  }
  @media screen and (max-width: 575px) {
    padding: 45px 0 0 0;

    .imgPHG {
      width: 130px;
      left: 0;
    }
    .imgPHG2 {
      width: 170px;
    }
  }
`
const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 30px;
`
const TextHead = styled.div`
  margin-bottom: 10px;
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  width: 100%;
  max-width: 1440px;
  height: 70px;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  // background: linear-gradient(
  //   270deg,
  //   rgba(37, 36, 51, 0.2) 1.16%,
  //   rgba(37, 36, 51, 0.8) 54.59%,
  //   rgba(37, 36, 51, 0.2) 100%
  // );
  // backdrop-filter: blur(8px);
  display: flex;
  align-items: center;
  .text {
    animation: marquee 30s linear infinite;
  }
  @keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
`
const HomePage: React.FC<React.PropsWithChildren> = () => {
  const { isMobile, isTablet } = useMatchBreakpoints()
  const [corePrice, setCorePrice] = useState(0)
  const CHAIN_ID = Number(process.env.NEXT_PUBLIC_DEFAULT_CHAIN)
  const { data: signer } = useSigner()
  const [tokenPrice, setTokenPrice] = useState(0)
  const getPoolsContract = getContract({ address: addresses.pools[CHAIN_ID], abi: poolAbi, chainId: CHAIN_ID, signer })
  const getCorePrice = async () => {
    const [price, tokenPrice] = await Promise.all([getPoolsContract.CORE2USDT(), getPoolsContract.CST2USDT()])
    setCorePrice(Number(formatEther(price)))
    setTokenPrice(Number(formatEther(tokenPrice)))
  }
  const [price, setPrice] = useState({
    core: 0,
    coreChange: 0,
    cst: 0,
    cstChange: 0,
  })

  useEffect(() => {
    getPriceTrend()
  }, [])
  const getPriceTrend = async () => {
    const headers = {
      'X-API-Key': '01e54e9712d16936f7a4a333fc6c789f',
      'Content-Type': 'application/json', // (Nếu có)
      // Các headers khác nếu cần
    }
    const baseURL = `https://api.corestake.org/coins/markets`
    const URL = `https://api.corestake.org/token?chain=coredao&address=0x92edf9d66581283e8c7502f288b7be37d25d3722`
    const [core, cst] = await Promise.all([axios.get(baseURL), axios.get(URL)])

    setPrice({
      core: core.data.current_price,
      coreChange: core.data.price_change_percentage_24h,
      cst: cst.data.data.price,
      cstChange: cst.data.data.price24h
        ? ((cst.data.data.price - cst.data.data.price24h.price) * 100) / cst.data.data.price
        : 0,
    })
  }

  useEffect(() => {
    getCorePrice()
  }, [])

  return (
    <>
      <style jsx global>{`
        #home-1 .page-bg {
          background: linear-gradient(0deg, #faf9fa 0%, rgba(255, 255, 255, 0.7) 41.67%, rgba(255, 255, 255, 0) 58.85%);
        }

        [data-theme='dark'] #home-1 .page-bg {
          background: linear-gradient(0deg, #08060b 3.12%, rgba(8, 6, 11, 0.7) 17.19%, rgba(8, 6, 11, 0) 58.85%);
        }
      `}</style>
      <Wrapper>
        {/* <CurrencyExchange /> */}
        <Container>
          <TextHead
            style={{
              display: isMobile || isTablet ? 'flex' : 'none',
            }}
          >
            <Flex gap="10px" alignItems="center" className="text" mt="20px">
              <Flex
                bg="rgba(70, 217, 46, 0.15)"
                borderRadius="10px"
                p="20px 30px 20px 10px"
                gap="10px"
                alignItems="center"
              >
                <Text
                  color="#fff"
                  fontFamily="Poppins,sans-serif"
                  fontSize={isTablet ? '10px' : '16px'}
                  fontWeight={600}
                >
                  CORE:
                </Text>
                <img src="/images/core/coreIcon.svg" />
                <Text
                  color="#fff"
                  fontFamily="Poppins,sans-serif"
                  fontSize={isTablet ? '10px' : '16px'}
                  fontWeight={600}
                >
                  {price.core.toFixed(3)}
                </Text>
                {price.coreChange >= 0 ? (
                  <Text style={{ color: '#46D92E', fontWeight: 400 }}>
                    +
                    <CountUp
                      separator=","
                      start={0}
                      preserveValue
                      delay={0}
                      // end={Number(Number(balance) * rateBnbUsd)}
                      decimals={2}
                      duration={1}
                      end={Number(price.coreChange)}
                    />
                    %
                  </Text>
                ) : (
                  <Text style={{ color: '#E30721', fontWeight: 400 }}>
                    <CountUp
                      separator=","
                      start={0}
                      preserveValue
                      delay={0}
                      // end={Number(Number(balance) * rateBnbUsd)}
                      decimals={2}
                      duration={1}
                      end={Number(price.coreChange)}
                    />
                    %
                  </Text>
                )}
              </Flex>
              <Flex
                bg="rgba(70, 217, 46, 0.15)"
                borderRadius="10px"
                p="20px 30px 20px 10px"
                gap="10px"
                alignItems="center"
              >
                <Text
                  color="#fff"
                  fontFamily="Poppins,sans-serif"
                  fontSize={isTablet ? '10px' : '16px'}
                  fontWeight={600}
                >
                  CST:
                </Text>
                <img src="/images/core/cstIcon.svg" />
                <Text
                  color="#fff"
                  fontFamily="Poppins,sans-serif"
                  fontSize={isTablet ? '10px' : '16px'}
                  fontWeight={600}
                >
                  {' '}
                  {price.cst.toFixed(5)}
                </Text>
                {price.cstChange >= 0 ? (
                  <Text style={{ color: '#46D92E', fontWeight: 400 }}>
                    +
                    <CountUp
                      separator=","
                      start={0}
                      preserveValue
                      delay={0}
                      // end={Number(Number(balance) * rateBnbUsd)}
                      decimals={2}
                      duration={1}
                      end={Number(price.cstChange)}
                    />
                    %
                  </Text>
                ) : (
                  <Text style={{ color: '#E30721', fontWeight: 400 }}>
                    <CountUp
                      separator=","
                      start={0}
                      preserveValue
                      delay={0}
                      // end={Number(Number(balance) * rateBnbUsd)}
                      decimals={2}
                      duration={1}
                      end={Number(price.cstChange)}
                    />
                    %
                  </Text>
                )}
              </Flex>
            </Flex>
          </TextHead>

          {/* <Head /> */}
          <Section1 />
          {/* <Section2 /> */}
          <Section3 />
          <Section4 />
          <Section5 />
          {/* <Stake />
          <HowToUse />
          <Tokenomics />
          <RoadMap />
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              margin: isMobile ? '30px 0 0 0' : '-20px 80px 0px 0px',
            }}
          >
            <img className="imgPHG2" src="/images/core/imgHome1.png" />
          </div> */}
        </Container>
      </Wrapper>
    </>
  )
}

export default HomePage

import { Divider, Stack } from '@chakra-ui/react'
import { formatEther } from '@ethersproject/units'
import { Button, Text, useMatchBreakpoints } from '@pancakeswap/uikit'
import { Text02 } from 'configs/fornt'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useEffect, useState } from 'react'
import CountUp from 'react-countup'
import styled from 'styled-components'

const Note = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media screen and (max-width: 575px) {
    padding: 16px;
  }
`
const NoteItems = styled.div`
  display: flex;
  gap: 10px;

  align-items: center;
  justify-content: center;
`

export const ImageRank = styled.img`
  @media screen and (min-width: 1024px) {
    width: 54px;
    height: 60px;
  }
  @media (max-width: 1023px) {
    width: 60px;
    height: 60px;
  }
`
const CardRank = styled.div`
  max-width: 386px;
  height: auto;
  color: #fff;
  border-radius: 20px;
  border: 1px solid var(--blur, #4d4d4d);
  background: #1d1617;
  backdrop-filter: blur(8px);
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 24px;
  margin-top: 20px;

  @media screen and (max-width: 575px) {
    padding: 16px;
  }
  @media screen and (max-width: 800px) {
    width: 336px;
  }
  position: relative;
`
const CardHead = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
`

const HeadLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

const HeadRight = styled.div`
  background-image: url('/images/core/rankBorder.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 10px;
  position: absolute;
  top: -10%;
  right: 40%;
  left: 40%;
  @media (max-width: 575px) {
    top: -15%;
    right: 40%;
  }
`

const TitleHeadRight = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 120%;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: inherit;
  @media (max-width: 739px) {
    font-size: 20px;
  }
  @media (max-width: 575px) {
    font-size: 16px;
  }
`

const TitleHeadRightBronze = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  justify-content: center;
  color: inherit;
  @media (max-width: 739px) {
    font-size: 20px;
  }
  @media (max-width: 575px) {
    font-size: 16px;
  }
  width: 128px;
  height: 44px;
  @media (max-width: 575px) {
    width: 108px;
  }
`
const MinMaxPrice = styled.div`
  display: flex;
  justify-content: flex-start;
  color: inherit;
  gap: 50px;
  color: #67e4ff;
`

const MinMaxItem = styled.span`
  font-weight: 700;
  font-size: 12px;
  line-height: 100%;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: inherit;
  gap: 6px;
  color: #67e4ff;
`

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media screen and (max-width: 575px) {
    gap: 6px;
  }
`

const ItemInfoCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
`

const Label = styled.span`
  color: var(--greyscale-grey-scale-text-seconday, #adabb2);
  /* Text md/regular */
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;

  @media (max-width: 739px) {
    font-size: 14px;
  }
`

const Value = styled.span`
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
  display: flex;
  align-items: center;
  gap: 5px;
  @media (max-width: 739px) {
    font-size: 14px;
  }
`

const BorderCard = styled.div`
  border: 1px solid #fff;
  margin: 8px 0;
`

const StyledButtonRank = styled.button`
  width: 200px;
  height: 36px;
  color: #f3f3f3;
  border-radius: var(--border-radius-lg, 12px);
  background: var(--primary-primary-1, #8544f5);
  /* light effect/boxShadow */
  box-shadow: 2px 2px 8px 16px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  &:disabled {
    border-radius: 15px;
    border: 1px solid rgba(245, 251, 242, 0.2);
    opacity: 0.30000001192092896;
    background: rgba(131, 131, 145, 1);
    backdrop-filter: blur(50px);
    color: #f3f3f3;
    font-weight: 700;
  }
`
const ValueLocked = styled(Text)`
  /* Display xs/Bold */
  background: var(
    --border,
    linear-gradient(90deg, #e86e00 -1.64%, #f18900 3.29%, #f99f00 9.22%, #fdac00 14.15%, #ffb100 19.09%, #e86e00 97.07%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  display: flex;
  align-items: center;
  gap: 5px;
`
const UpRanks = styled(Button)`
  border-radius: var(--border-radius-lg, 8px);
  background: var(--primary-primary-1, #8544f5);
  /* light effect/boxShadow */
  box-shadow: 2px 2px 8px 16px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: rgba(255, 255, 255, 1);
`
const ListPoolRanks = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto;
  gap: 20px;
  margin-top: 50px;
  flex-direction: row;
  .card {
    position: relative;
    width: 300px;
    height: 200px;
    background-color: #3498db;
    overflow: hidden;
    transition: box-shadow 0.3s ease-in-out;
  }
  .active {
    border: 2px solid rgba(175, 69, 44, 1);
  }
  max-width: 1204px;
  @media screen and (max-width: 1300px) {
    justify-content: center;
  }
  @media screen and (max-width: 575px) {
    padding: 16px;
    grid-row-gap: 16px;
  }
`
const nextRankRequire = [
  {
    direct: 0,
    volumnOnTree: 0,
    totalLock: 0,
  },
  {
    direct: 3,
    volumnOnTree: 10000,
    totalLock: 100,
  },
  {
    direct: 3,
    volumnOnTree: 25000,
    totalLock: 100,
  },
  {
    direct: 3,
    volumnOnTree: 50000,
    totalLock: 1000,
  },
  {
    direct: 5,
    volumnOnTree: 150000,
    totalLock: 1000,
  },
  {
    direct: 5,
    volumnOnTree: 300000,
    totalLock: 2000,
  },
  {
    direct: 5,
    volumnOnTree: 500000,
    totalLock: 5000,
  },
  {
    direct: 5,
    volumnOnTree: 1000000,
    totalLock: 10000,
  },
  {
    direct: 10,
    volumnOnTree: 3000000,
    totalLock: 10000,
  },
]
const PoolRanks = ({ userRank, arrayTotalLock }) => {
  const { isMobile } = useMatchBreakpoints()
  const { account, chainId } = useActiveWeb3React()
  // account = '0xBd0b331A65C686085FC892D1b7A7B06C54a8858b'

  const myElement = document.getElementById('myElement')
  const [currentRank, setCurrentRank] = useState(0)
  var count = 0
  const canUpRank1 = currentRank === 8 ? true : userRank.direct >= nextRankRequire[currentRank + 1].direct
  const canUpRank2 = currentRank === 8 ? true : userRank.volumnOnTree >= nextRankRequire[currentRank + 1].volumnOnTree

  const checkTotalLockUSD = () => {
    if (canUpRank1 && arrayTotalLock.length > 0) {
      for (let i = 0; i <= arrayTotalLock.length - 1; i++) {
        if (Number(formatEther(arrayTotalLock[i])) > nextRankRequire[currentRank].totalLock) {
          count++
        }
      }
    }
    return count
  }
  const checkRank = () => {
    let rank = 0
    nextRankRequire.map((n, i) => {
      if (Number(userRank.direct) >= n.direct && userRank.volumnOnTree >= n.volumnOnTree) rank = i
      return n
    })
    setCurrentRank(rank)
  }
  const dataRank = [
    {
      title: 'C ZERO',
      img: '/images/core/Rank0.png',
      reward: 0,
    },
    {
      title: 'C ONE',
      img: '/images/core/Rank1.png',
      reward: 100,
    },
    {
      title: 'C TWO',
      img: '/images/core/Rank2.png',
      reward: 500,
    },
    {
      title: 'C THREE',
      img: '/images/core/Rank3.png',
      reward: 1000,
    },
    {
      title: 'C FOUR',
      img: '/images/core/Rank4.png',
      reward: 3000,
    },
    {
      title: 'C FIVE',
      img: '/images/core/Rank5.png',
      reward: 5000,
    },
    {
      title: 'C SIX',
      img: '/images/core/Rank6.png',
      reward: 10000,
    },
    {
      title: 'C SEVEN',
      img: '/images/core/Rank7.png',
      reward: 20000,
    },
    {
      title: 'C EIGHT',
      img: '/images/core/Rank8.png',
      reward: 50000,
    },
  ]
  useEffect(() => {
    checkTotalLockUSD()
    checkRank()
  }, [account])

  return (
    <>
      <ListPoolRanks>
        {dataRank.map((items, r) => (
          <CardRank className={currentRank === r ? 'active' : ''} key={r}>
            {r <= currentRank ? null : (
              <div
                style={{
                  background: '#25243340',
                  backdropFilter: 'blur(15px)',
                  opacity: '1',
                  border: '1px solid rgba(255, 255, 255, 0.50)',
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  top: '0%',
                  right: '0%',
                  borderRadius: '20px',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  flexDirection: 'column',
                  gap: '10px',
                  padding: '50px',
                }}
              >
                <img src="/images/core/lock.svg" />
                <Text02>Unlock {dataRank[r].title} rank to show</Text02>
              </div>
            )}

            <CardHead>
              <Stack justifyContent="center" alignItems="center" mt="35px">
                <TitleHeadRight style={{ color: '#fff', textAlign: 'center' }}>{items.title}</TitleHeadRight>
              </Stack>

              <HeadRight>
                <img style={{ width: '60px' }} src={items.img} />
              </HeadRight>
            </CardHead>
            <CardBody>
              <ItemInfoCard
                style={{
                  color: '#fff',
                }}
              >
                <Label>Rewards</Label>
                <Value>
                  <div>
                    ${' '}
                    <CountUp
                      separator=","
                      start={0}
                      preserveValue
                      delay={0}
                      end={items.reward}
                      decimals={0}
                      duration={0.5}
                    />
                  </div>
                </Value>
              </ItemInfoCard>
              {currentRank === r || r === currentRank + 1 ? <Divider color="rgba(255, 255, 255, 0.15)" /> : <></>}

              <ItemInfoCard
                style={{
                  color:
                    (canUpRank1 && r === currentRank + 1) || (canUpRank1 && r === currentRank) || currentRank > r
                      ? '#fff'
                      : 'gray',
                }}
              >
                <Label>Member direct</Label>
                <Value>
                  <>
                    <CountUp
                      separator=","
                      start={0}
                      preserveValue
                      delay={0}
                      end={Number(userRank.direct)}
                      decimals={0}
                      duration={0.5}
                    />{' '}
                    /{' '}
                    <CountUp
                      separator=","
                      start={0}
                      preserveValue
                      delay={0}
                      end={nextRankRequire[r].direct}
                      decimals={0}
                      duration={0.5}
                    />
                  </>
                </Value>
              </ItemInfoCard>
              {currentRank === r || r === currentRank + 1 ? <Divider color="rgba(255, 255, 255, 0.15)" /> : <></>}

              <ItemInfoCard
                style={{
                  color:
                    (canUpRank2 && r === currentRank + 1) || (canUpRank2 && r === currentRank) || currentRank > r
                      ? '#fff'
                      : 'gray',
                }}
              >
                <Label>Volume on tree</Label>
                <ValueLocked>
                  <Value>
                    <>
                      $
                      <CountUp
                        separator=","
                        start={0}
                        preserveValue
                        delay={0}
                        end={userRank.volumnOnTree}
                        decimals={userRank.volumnOnTree > 0 ? 3 : 0}
                        duration={0.5}
                      />{' '}
                      / $
                      <CountUp
                        separator=","
                        start={0}
                        preserveValue
                        delay={0}
                        end={nextRankRequire[r].volumnOnTree}
                        decimals={0}
                        duration={0.5}
                      />
                    </>
                  </Value>
                </ValueLocked>
              </ItemInfoCard>
            </CardBody>
          </CardRank>
        ))}
      </ListPoolRanks>
    </>
  )
}

export default PoolRanks

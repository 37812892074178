/* eslint-disable react/button-has-type */
import { Flex, Stack, Text } from '@chakra-ui/react'
import { formatEther } from '@ethersproject/units'
import { Button, useMatchBreakpoints, useToast } from '@pancakeswap/uikit'
import { ButtonStake } from 'components/ButtonStake'
import { ThreeDots } from 'components/Loader/TextLoading'
import { ToastDescriptionWithTx } from 'components/Toast'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useCallWithGasPrice } from 'hooks/useCallWithGasPrice'
import useClaimRewardTransaction from 'hooks/useClaimRewardTransaction'
import { usePoolsContract } from 'hooks/useContract'
import moment from 'moment'
import numeral from 'numeral'
import { useEffect, useRef, useState } from 'react'
import CountUp from 'react-countup'
import styled from 'styled-components'
import { Text02 } from '../../../configs/fornt'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding: 50px;
  z-index: 2;
  .buttonHistory:disabled {
    cursor: not-allowed;
  }
  @media screen and (max-width: 1200px) {
    justify-content: center;
    padding: 0;

    align-items: center;
  }
  @media screen and (max-width: 900px) {
    justify-content: center;
    padding: 0;

    align-items: center;
  }
`

const Th = styled(Text)`
  display: flex;
  justify-content: center;
  width: 200px;

  align-items: center;
  @media screen and (max-width: 575px) {
    padding: 0 14px;
  }
`
const Td = styled(Text)`
  display: flex;
  justify-content: center;
  width: 200px;
  align-items: center;
  gap: 5px;
  @media screen and (max-width: 575px) {
    padding: 0 14px;
  }
`
const TablePool = styled.div`
  max-width: 1440px;
  width: 100%;
  // display: flex;
  // gap: 0.5em;
  // align-items: center;
  // justify-content: center;
  background: var(--black-black-5, rgba(0, 0, 0, 0.05));
  margin: 20px auto;
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    padding: 10px 20px;
  }
  ::-webkit-scrollbar-track {
    background-color: white;
  }
  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background: rgb(0, 240, 225);
  }
  @media screen and (max-width: 1200px) {
    overflow-x: auto;
    padding: 10px 20px;
    width: 100%;
    max-width: 900px;
  }
  @media screen and (max-width: 900px) {
    overflow-x: auto;
    padding: 10px 20px;
    width: 100%;
    max-width: 700px;
  }
  @media screen and (max-width: 575px) {
    display: none;
    // overflow-x: auto;
    width: 100%;
    padding: 0;
  }
`
const TableScroll = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  thead {
    padding: 10px;
    border-radius: 3px;
    border-top: 4px solid transparent;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: none;

    background-image: linear-gradient(#3f2914, #3f2914), linear-gradient(180deg, #f9b845 27%, #af452c 100%);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    display: flex;
  }
  tr {
    display: flex;
    height: 40px;
    align-items: center;
  }
  tbody {
    padding: 40px 10px;

    display: flex;
    border-top: 1px solid #f4b044;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 2px solid #f4b044;
    background-image: linear-gradient(#1d1617, #1d1617), linear-gradient(180deg, #f9b845 27%, #af452c 100%);
    background-origin: border-box;
    background-clip: padding-box, border-box;

    backdrop-filter: blur(8px);
    height: 40px;
    align-items: center;
  }
  @media screen and (max-width: 1200px) {
    width: 100%;
  }
`
const Page = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  .active {
    font-size: 15px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    color: #fff;
    background: #ff9400;
  }
  .inActive {
    font-size: 15px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    color: rgba(255, 255, 255, 0.7);
    background: none;
  }
`
const PageNumber = styled(Button)`
  border-radius: 1.067px;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
`

const StakedHistory = ({ dataPools, getPoolsUsers, poolsCt, isLoading }) => {
  // console.log(dataPools)
  const { account, chainId } = useActiveWeb3React()
  // account = '0xd27eAB4BaCcb63283245a2A6c8987f91ECF40cdA'
  const { isMobile } = useMatchBreakpoints()
  const [loadingClaim, setLoadingClaim] = useState(false)
  const { toastSuccess } = useToast()
  const { callWithGasPrice } = useCallWithGasPrice()
  const [dataUserClaimed, setDataUserClaimed] = useState([])
  const [currentPool, setCurrentPool] = useState(0)
  const currentPage = useRef(0)
  const [corePrice, setCorePrice] = useState(0)
  const limitPage = useRef(5)
  const [usersClaimedLength, setUsersClaimedLength] = useState(0)
  // Claim Reward
  const { isConfirmingClaimReward, handleConfirmClaimReward } = useClaimRewardTransaction({
    onConfirm: () => {
      setLoadingClaim(true)
      getPoolsUsers()
      return callWithGasPrice(dataPools[currentPool].contract, 'claimReward', [dataPools[currentPool].poolId])
    },
    onSuccess: async ({ receipt }) => {
      getDataClaimed()
      getPoolsUsers()
      setLoadingClaim(false)
      toastSuccess('Claim successfully !', <ToastDescriptionWithTx txHash={receipt.transactionHash} />)
    },
  })
  // get history claimed
  const getDataClaimed = async () => {
    const core2USDT = await poolsCt.CORE2USDT()
    if (dataPools.length > 0) {
      const getUsersClaimedLength = await dataPools[currentPool].contract.getUsersClaimedLength(
        dataPools[currentPool].poolId,
        account,
      )
      // console.log(dataPools, getUsersClaimedLength, currentPool)
      if (Number(getUsersClaimedLength) > 0) {
        const getUsersClaimed = await dataPools[currentPool].contract.getUsersClaimed(
          dataPools[currentPool].poolId,
          account,
          Number(getUsersClaimedLength),
          0,
        )
        const data = getUsersClaimed.list
        const claimedHistory = await Promise.all(
          data.map((items, r) => {
            return {
              claimedInterest: ((Number(data[r].interrest.toString()) / 10000) * 365).toFixed(2),
              dateClaimed: Number(data[r].date),
              totalLockUSD: Number(items.totalLockUSD),
              amountClaimed: Number(formatEther(data[r].amount)),
              amountUSDClaimed: Number(formatEther(data[r].amountUSD)),
            }
          }),
        )
        setDataUserClaimed(claimedHistory)
      }

      setCorePrice(Number(formatEther(core2USDT)))
      setUsersClaimedLength(Number(getUsersClaimedLength))
    }
  }
  //

  const getData = () => {
    if (dataUserClaimed.length > 0) {
      const newDataClaimed = dataUserClaimed
        .sort((a, b) => b.dateClaimed - a.dateClaimed)
        .slice(currentPage.current, limitPage.current)
      return newDataClaimed
    }

    return []
  }
  useEffect(() => {
    getDataClaimed()
  }, [account, currentPool, dataPools])
  // Change Pools
  const handleChangeNextPools = () => {
    setCurrentPool(currentPool + 1)
    handleChangePage(0)
  }
  const handleChangePrePools = () => {
    setCurrentPool(currentPool - 1)
    handleChangePage(0)
  }
  const handleChangePage = (r) => {
    const nextPageStartIndex = r * 5
    const nextPageEndIndex = (r + 1) * 5
    if (nextPageStartIndex < dataUserClaimed.length) {
      currentPage.current = nextPageStartIndex
      limitPage.current = nextPageEndIndex
      // getPageData();
      getData()
    } else {
      console.log('Không có dữ liệu ở trang tiếp theo')
    }
    // getPageData()
  }
  const getTotalPage = () => {
    if (dataUserClaimed.length > 0) {
      const arrayPage = Array.from({ length: Math.ceil(dataUserClaimed.length / 5) }, (_, i) => i + 1)
      // const arrayPage = Array.from({ length: Math.ceil(10) }, (_, i) => i + 1)

      return arrayPage
    }
    return []
  }
  const handlePrevPage = () => {
    if (currentPage.current > 0) {
      handleChangePage(currentPage.current / 5 - 1)
    }
  }
  const handleNextPage = () => {
    handleChangePage(currentPage.current / 5 + 1)
  }
  const renderPageNumbers = () => {
    const totalPages = getTotalPage()

    if (totalPages.length <= 0) {
      return null
    }
    const displayRange = 7 // Number of pages to display on either side of the current page
    if (totalPages.length > displayRange) {
      return (
        <>
          {currentPage.current / 5 + 1 === totalPages.length ||
          currentPage.current / 5 + 1 === totalPages.length - 1 ||
          currentPage.current / 5 + 1 === totalPages.length - 2 ? (
            <div style={{ display: 'flex', gap: '16px' }}>
              <PageNumber
                onClick={() => handleChangePage(totalPages[0] - 1)}
                className={totalPages[0] - 1 === currentPage.current / 5 + 1 ? 'active' : 'inActive'}
              >
                {totalPages[0]}
              </PageNumber>

              <Text style={{ fontSize: '24px', color: 'rgba(255, 255, 255, 0.70)' }}>...</Text>
              <PageNumber
                onClick={() => handleChangePage(totalPages.length - 3)}
                className={totalPages.length - 2 === currentPage.current / 5 + 1 ? 'active' : 'inActive'}
              >
                {totalPages.length - 2}
              </PageNumber>
              <PageNumber
                onClick={() => handleChangePage(totalPages.length - 2)}
                className={totalPages.length - 1 === currentPage.current / 5 + 1 ? 'active' : 'inActive'}
              >
                {totalPages.length - 1}
              </PageNumber>
              <PageNumber
                onClick={() => handleChangePage(totalPages.length - 1)}
                className={totalPages.length === currentPage.current / 5 + 1 ? 'active' : 'inActive'}
              >
                {totalPages.length}
              </PageNumber>
            </div>
          ) : (
            <div style={{ display: 'flex', gap: '16px' }}>
              <div style={{ display: 'flex', gap: '16px' }}>
                {currentPage.current / 5 + 1 === 1 ? null : (
                  <PageNumber onClick={() => handleChangePage(currentPage.current / 5 - 1)} className="inActive">
                    {currentPage.current / 5}
                  </PageNumber>
                )}
                <PageNumber className={currentPage.current / 5 + 1 ? 'active' : 'inActive'}>
                  {currentPage.current / 5 + 1}
                </PageNumber>
                <PageNumber onClick={() => handleChangePage(currentPage.current / 5 + 1)} className="inActive">
                  {currentPage.current / 5 + 2}
                </PageNumber>
              </div>

              <Text style={{ fontSize: '24px', color: 'rgba(255, 255, 255, 0.70)' }}>...</Text>
              <PageNumber
                onClick={() => handleChangePage(totalPages.length - 1)}
                className={totalPages.length === currentPage.current / 5 + 1 ? 'active' : 'inActive'}
              >
                {totalPages.length}
              </PageNumber>
            </div>
          )}
        </>
      )
    }
    // eslint-disable-next-line no-else-return
    else {
      return totalPages.map((pageNumber, index) => (
        <PageNumber
          onClick={() => (typeof pageNumber === 'number' ? handleChangePage(pageNumber - 1) : null)}
          className={typeof pageNumber === 'number' && index === currentPage.current / 5 ? 'active' : 'inActive'}
          key={index}
        >
          {pageNumber}
        </PageNumber>
      ))
    }
  }
  return (
    <Wrapper>
      <Stack w={isMobile ? '100%' : 'none'}>
        <Flex gap="20px" mt="100px">
          <Stack display={isMobile ? 'none' : 'flex'} w="4px" h="214px" bg="#FF9400" />
          <Stack>
            <Text
              color="#FFF"
              fontFamily="Poppins,sans-serif"
              fontSize="48px"
              fontStyle="normal"
              fontWeight={275}
              lineHeight="130%"
            >
              Staking{' '}
              <span
                style={{
                  color: '#FFF',
                  fontFamily: 'Poppins,sans-serif',
                  fontSize: '48px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '130%',
                }}
              >
                History
              </span>
            </Text>
            <Text
              w={isMobile ? '100%' : '600px'}
              color="rgba(255, 255, 255, 0.70)"
              fontFamily="Poppins,sans-serif"
              fontSize="16px"
              fontStyle="normal"
              fontWeight={400}
              lineHeight="24px"
            >
              To check your history of staking on Core Stake, please ensure that you have already staked before using
              this feature.
            </Text>
            <Flex direction={isMobile ? 'column' : 'row'} gap="10px" alignItems="center">
              <Flex gap="10px" alignItems="center" justifyContent="space-between" w="full">
                <Flex w="100%" p={isMobile ? '10px' : '16px'} borderRadius="4px" bg="#2f292a" gap="10px">
                  <img src="/images/core/ustdIcon.svg" />
                  <CountUp
                    start={0}
                    preserveValue
                    delay={0}
                    end={dataPools[currentPool]?.currentReward * corePrice}
                    decimals={dataPools[currentPool]?.currentReward > 0 ? 4 : 0}
                    duration={0.5}
                    style={{
                      marginLeft: 6,
                      color: '#FFF',
                      fontFamily: 'Poppins,sans-serif',
                      fontSize: isMobile ? '13px' : '16px',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      lineHeight: '24px',
                    }}
                  />{' '}
                  <span
                    style={{
                      color: '#FFF',
                      fontFamily: 'Poppins,sans-serif',
                      fontSize: isMobile ? '13px' : '16px',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      lineHeight: '24px',
                    }}
                  >
                    USDT
                  </span>
                </Flex>
                ~
                <Flex w="100%" p={isMobile ? '10px' : '16px'} borderRadius="4px" bg="#2f292a" gap="10px">
                  <img src="/images/core/coreIcon.svg" />
                  <CountUp
                    start={0}
                    preserveValue
                    delay={0}
                    end={dataPools[currentPool]?.currentReward - dataPools[currentPool]?.currentReward * 0.025}
                    decimals={dataPools[currentPool]?.currentReward > 0 ? 4 : 0}
                    duration={0.5}
                    style={{
                      marginLeft: 6,
                      color: '#FFF',
                      fontFamily: 'Poppins,sans-serif',
                      fontSize: isMobile ? '13px' : '16px',

                      fontStyle: 'normal',
                      fontWeight: 600,
                      lineHeight: '24px',
                    }}
                  />{' '}
                  <span
                    style={{
                      color: '#FFF',
                      fontFamily: 'Poppins,sans-serif',
                      fontSize: isMobile ? '13px' : '16px',

                      fontStyle: 'normal',
                      fontWeight: 600,
                      lineHeight: '24px',
                    }}
                  >
                    CORE
                  </span>
                </Flex>
              </Flex>
              {loadingClaim === true && isConfirmingClaimReward ? (
                <ButtonStake style={{ width: '100px', cursor: 'not-allowed', marginTop: '0' }}>
                  <div className="loader"></div>
                </ButtonStake>
              ) : (
                <ButtonStake
                  style={{ width: isMobile ? '100%' : '100px', marginTop: '0' }}
                  onClick={handleConfirmClaimReward}
                  disabled={dataPools[currentPool]?.currentReward === 0 || !dataPools[currentPool]?.currentReward}
                >
                  <div>Claim</div>
                </ButtonStake>
              )}
            </Flex>
          </Stack>
        </Flex>
        <div
          style={{
            width: '100%',
            maxWidth: '1000px',
            justifyContent: isMobile ? 'center' : 'flex-end',
            gap: '20px',
          }}
        >
          <ButtonStake
            onClick={handleChangePrePools}
            disabled={dataPools.length === 0 || dataPools.length === 1 || currentPool === 0}
            style={{ width: '100px', height: '35px', borderRadius: '4px', background: '#FF9400' }}
          >
            Prev
          </ButtonStake>
          <ButtonStake
            onClick={handleChangeNextPools}
            disabled={dataPools.length === 0 || dataPools.length - 1 === currentPool}
            style={{ width: '100px', height: '35px', borderRadius: '4px', background: '#FF9400', marginLeft: '10px' }}
          >
            Next
          </ButtonStake>
        </div>
        {usersClaimedLength > 0 && (
          <>
            <TablePool>
              <TableScroll className="scroll">
                <thead>
                  <tr>
                    <Th textAlign="left">
                      <Text className="th" style={{ color: '#FFF', fontWeight: '600' }} textTransform="capitalize">
                        Interest (%)
                      </Text>
                    </Th>
                    <Th textAlign="center">
                      <Text style={{ color: '#FFF', fontWeight: '600' }} textTransform="capitalize">
                        Time
                      </Text>
                    </Th>
                    {/* <Th textAlign="right">
                      <Text style={{ color: '#FFF', fontWeight: '600' }} textTransform="capitalize">
                        Withdraw
                      </Text>{' '}
                    </Th> */}
                    <Th textAlign="right">
                      <Text style={{ color: '#FFF', fontWeight: '600' }} textTransform="capitalize">
                        Remain
                      </Text>{' '}
                    </Th>{' '}
                    <Th textAlign="right">
                      <Text style={{ color: '#FFF', fontWeight: '600' }} textTransform="capitalize">
                        {' '}
                        Amount
                      </Text>{' '}
                    </Th>
                    <Th textAlign="left">
                      <Text style={{ color: '#FFF', fontWeight: '600' }} textTransform="capitalize">
                        Status
                      </Text>{' '}
                    </Th>
                  </tr>
                </thead>
                {isLoading === true ? (
                  dataPools.length === 0 ? (
                    <Text02 style={{ color: '#FFF', fontSize: '24px', padding: '10px' }}>Stake to claim</Text02>
                  ) : (
                    <>
                      <ThreeDots style={{ color: '#FFF', fontSize: '24px', padding: '10px' }} className="loading">
                        Loading<span>.</span>
                        <span>.</span>
                        <span>.</span>
                      </ThreeDots>
                    </>
                  )
                ) : (
                  <>
                    {usersClaimedLength > 0 && (
                      <>
                        {getData().map((items, index) => (
                          <tbody key={index}>
                            <Td>
                              <Text style={{ color: '#FFF', fontWeight: '600' }} textTransform="capitalize">
                                {items.claimedInterest}
                              </Text>
                            </Td>

                            <Td>
                              <Text style={{ color: '#FFF', fontWeight: '600' }} textTransform="capitalize">
                                {moment.unix(items.dateClaimed * 86400).format('DD-MM-YYYY')}
                              </Text>
                            </Td>

                            {/* <Td>
                              <Text style={{ color: '#FFF', fontWeight: '600' }} textTransform="capitalize">
                                $
                                <CountUp
                                  start={0}
                                  preserveValue
                                  delay={0}
                                  end={dataPools[currentPool]?.currentReward * corePrice}
                                  decimals={dataPools[currentPool]?.currentReward > 0 ? 4 : 0}
                                  duration={0.5}
                                  style={{ marginLeft: 6 }}
                                />
                              </Text>
                            </Td> */}
                            <Td>
                              <Text style={{ color: '#FFF', fontWeight: '600' }} textTransform="capitalize">
                                ${numeral(dataPools[currentPool]?.remainUSD).format('0,0')}
                              </Text>
                            </Td>
                            <Td>
                              <Text style={{ color: '#FFF', fontWeight: '600' }} textTransform="capitalize">
                                $
                                <CountUp
                                  start={0}
                                  preserveValue
                                  delay={0}
                                  end={items.amountUSDClaimed}
                                  decimals={items.amountUSDClaimed > 0 ? 3 : 0}
                                  duration={0.5}
                                  style={{ marginLeft: 6 }}
                                />
                              </Text>
                            </Td>
                            <Td>
                              <Flex gap="10px" alignItems="center" justifyContent="center">
                                <Text style={{ color: '#3FD739', fontWeight: '600' }} textTransform="capitalize">
                                  Success
                                </Text>
                                <img src="/images/core/successIcon.svg" />
                              </Flex>
                            </Td>
                          </tbody>
                        ))}
                      </>
                    )}
                  </>
                )}
              </TableScroll>
            </TablePool>
            <Stack display={isMobile ? 'flex' : 'none'} mt={isMobile ? '10px' : '30px'}>
              {usersClaimedLength > 0 && (
                <>
                  {getData().map((items, index) => (
                    <Stack
                      key={index}
                      p="20px"
                      borderRadius="8px"
                      justifyContent="space-between"
                      border="2px solid transparent"
                      sx={{
                        backgroundImage:
                          'linear-gradient(#1d1617, #1d1617), linear-gradient(180deg, #ff9400 0%, #d97059 100%)',
                        backgroundOrigin: 'border-box',
                        backgroundClip: 'padding-box, border-box',
                      }}
                    >
                      <Flex justifyContent="space-between">
                        <Stack>
                          <Text
                            color="rgba(255, 255, 255, 0.40)"
                            fontFamily="Poppins,sans-serif"
                            fontSize="12px"
                            fontStyle="normal"
                            fontWeight={400}
                            lineHeight="150%"
                          >
                            Interest(%)
                          </Text>
                          <Text
                            color="#fff"
                            fontFamily="Poppins,sans-serif"
                            fontSize="16px"
                            fontStyle="normal"
                            fontWeight={600}
                            lineHeight="24px"
                          >
                            {items.claimedInterest}
                          </Text>
                        </Stack>
                        <Stack>
                          <Text
                            color="rgba(255, 255, 255, 0.40)"
                            fontFamily="Poppins,sans-serif"
                            fontSize="12px"
                            fontStyle="normal"
                            fontWeight={400}
                            lineHeight="150%"
                          >
                            Amount
                          </Text>
                          <Text
                            color="#fff"
                            fontFamily="Poppins,sans-serif"
                            fontSize="16px"
                            fontStyle="normal"
                            fontWeight={600}
                            lineHeight="24px"
                          >
                            $
                            <CountUp
                              start={0}
                              preserveValue
                              delay={0}
                              end={items.amountUSDClaimed}
                              decimals={items.amountUSDClaimed > 0 ? 3 : 0}
                              duration={0.5}
                              style={{ marginLeft: 6 }}
                            />
                          </Text>
                        </Stack>
                        <Stack>
                          <Text
                            color="rgba(255, 255, 255, 0.40)"
                            fontFamily="Poppins,sans-serif"
                            fontSize="12px"
                            fontStyle="normal"
                            fontWeight={400}
                            lineHeight="150%"
                          >
                            Time
                          </Text>
                          <Text
                            color="#fff"
                            fontFamily="Poppins,sans-serif"
                            fontSize="16px"
                            fontStyle="normal"
                            fontWeight={600}
                            lineHeight="24px"
                          >
                            {moment.unix(items.dateClaimed * 86400).format('DD-MM-YYYY')}
                          </Text>
                        </Stack>
                      </Flex>
                      <Flex justifyContent="space-between">
                        <Stack>
                          <Text
                            color="rgba(255, 255, 255, 0.40)"
                            fontFamily="Poppins,sans-serif"
                            fontSize="12px"
                            fontStyle="normal"
                            fontWeight={400}
                            lineHeight="150%"
                          >
                            Remain
                          </Text>
                          <Text
                            color="#fff"
                            fontFamily="Poppins,sans-serif"
                            fontSize="16px"
                            fontStyle="normal"
                            fontWeight={600}
                            lineHeight="24px"
                          >
                            ${numeral(dataPools[currentPool]?.remainUSD).format('0,0')}
                          </Text>
                        </Stack>

                        <Stack>
                          <Text
                            color="rgba(255, 255, 255, 0.40)"
                            fontFamily="Poppins,sans-serif"
                            fontSize="12px"
                            fontStyle="normal"
                            fontWeight={400}
                            lineHeight="150%"
                          >
                            Withdraw
                          </Text>
                          <Text
                            color="#fff"
                            fontFamily="Poppins,sans-serif"
                            fontSize="16px"
                            fontStyle="normal"
                            fontWeight={600}
                            lineHeight="24px"
                          >
                            $
                            <CountUp
                              start={0}
                              preserveValue
                              delay={0}
                              end={dataPools[currentPool]?.currentReward * corePrice}
                              decimals={dataPools[currentPool]?.currentReward > 0 ? 4 : 0}
                              duration={0.5}
                              style={{ marginLeft: 6 }}
                            />
                          </Text>
                        </Stack>
                        <Stack>
                          <Text
                            color="rgba(255, 255, 255, 0.40)"
                            fontFamily="Poppins,sans-serif"
                            fontSize="12px"
                            fontStyle="normal"
                            fontWeight={400}
                            lineHeight="150%"
                          >
                            Status
                          </Text>
                          <Flex gap="10px" alignItems="center" justifyContent="center">
                            <Text style={{ color: '#3FD739', fontWeight: '600' }} textTransform="capitalize">
                              Success
                            </Text>
                            <img src="/images/core/successIcon.svg" />
                          </Flex>
                        </Stack>
                      </Flex>
                    </Stack>
                  ))}
                </>
              )}
            </Stack>
          </>
        )}
        {usersClaimedLength > 0 && (
          <Flex justifyContent="center" alignItems="center" gap="10px">
            <button onClick={handlePrevPage} disabled={currentPage.current === 0} className="buttonHistory">
              <img src="/images/core/prevHistory.svg" />
            </button>
            <Page>{renderPageNumbers()}</Page>
            <button
              onClick={handleNextPage}
              disabled={currentPage.current / 5 + 1 >= getTotalPage().length}
              className="buttonHistory"
            >
              <img src="/images/core/nextHistory.svg" />
            </button>
          </Flex>
        )}
      </Stack>
    </Wrapper>
  )
}
export default StakedHistory

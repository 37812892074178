import { formatEther } from '@ethersproject/units'
import { useMatchBreakpoints } from '@pancakeswap/uikit'
import poolAbi from 'config/abi/pools.json'
import addresses from 'config/constants/contracts'
import { ethers } from 'ethers'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { getContract } from 'utils/contractHelpers'
import { useBalance, useNetwork, useSigner } from 'wagmi'
import StakedHistory from '../components/StakeHistory'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 50px;
  z-index: 2;
  background-image: url('/images/core/section2.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 20%;
  margin-bottom: 0;
  @media (max-width: 1024px) {
    height: none;
  }
  @media (max-width: 575px) {
    padding: 20px;
    margin-bottom: 100px;
    background-position: center;
  }
`

const Section2 = ({ dataPools, getPoolsUsers, poolsCT, isLoading }) => {
  return (
    <Wrapper>
      <StakedHistory dataPools={dataPools} poolsCt={poolsCT} getPoolsUsers={getPoolsUsers} isLoading={isLoading} />
    </Wrapper>
  )
}
export default Section2

import { Flex, Stack, Text } from '@chakra-ui/react'
import { useMatchBreakpoints } from '@pancakeswap/uikit'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 50px;
  z-index: 2;
  background-image: url('/images/core/section4.png');
  background-size: cover;
  background-position: 50% 0;
  background-repeat: no-repeat;
  @media (max-width: 575px) {
    background-size: contain;
  }
`
const dataLeft = [
  {
    title: 'Listing Dex, Cex',
    percent: '20%',
    bar: 'radial-gradient(49.68% 49.68% at 50% 50%, #F890B1 0%, #F652A6 100%)',
  },
  {
    title: 'Market Maker',
    percent: '10%',
    bar: ' radial-gradient(53.07% 53.07% at 50% 50%, #EB87E4 0%, #E35BF6 100%)',
  },
  {
    title: 'Team dev (Unlock)',
    percent: '2.5%',
    bar: 'radial-gradient(49.68% 49.68% at 50% 50%, #C9F5C1 0%, #6ADF53 100%)',
  },
  {
    title: 'Partner + Gift (Unlock)',
    percent: '5%',
    bar: 'radial-gradient(49.68% 49.68% at 50% 50%, #48C8FF 0%, #3472EB 100%)',
  },
]
const dataRight = [
  {
    title: 'Community',
    percent: '50%',
    bar: ' radial-gradient(50.23% 50.23% at 50% 50%, #F5B44A 0%, #B1492D 100%)',
  },
  {
    title: 'Marketing',
    percent: '2.5%',
    bar: 'radial-gradient(50% 50% at 50% 50%, #F8D46B 0%, #F8D46B 100%)',
  },
  {
    title: 'Founder (Unlock)',
    percent: '10%',
    bar: 'radial-gradient(53.07% 53.07% at 50% 50%, #9A3BF2 0%, #7E20EC 100%)',
  },
]
const Section4 = () => {
  const { isMobile, isTablet } = useMatchBreakpoints()

  return (
    <Wrapper>
      <Stack gap="50px" alignItems="center" justifyContent="center">
        <Text
          color="#FFF"
          textAlign="center"
          fontFamily="Poppins,sans-serif"
          fontSize="48px"
          fontStyle="normal"
          fontWeight={700}
          lineHeight="130%"
        >
          Tokenomics
        </Text>
        <Flex justifyContent="center" gap="50px">
          <Stack justifyContent="center" gap="30px" display={isMobile ? 'none' : isTablet ? 'none' : 'flex'}>
            {dataLeft.map((i) => (
              <Flex gap="30px" direction="row-reverse" alignItems="center" key={i.title}>
                <Stack w="6px" bg={i.bar} h="50px" />
                <Stack justifyContent="flex-end" alignItems="end">
                  <Text
                    color="#FFF"
                    fontFamily="Poppins,sans-serif"
                    fontSize="20px"
                    fontStyle="normal"
                    fontWeight={700}
                    lineHeight="140%"
                  >
                    {i.percent}
                  </Text>
                  <Text
                    textAlign="end"
                    color="rgba(255, 255, 255, 0.70)"
                    fontFamily="Poppins,sans-serif"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight={400}
                    lineHeight="140%"
                  >
                    {i.title}
                  </Text>
                </Stack>
              </Flex>
            ))}
          </Stack>
          <img style={{ width: '80%' }} src="/images/core/newTokenomic.png" />
          <Stack justifyContent="center" gap="30px" display={isMobile ? 'none' : isTablet ? 'none' : 'flex'}>
            {dataRight.map((i) => (
              <Flex gap="30px" w="full" alignItems="center" key={i.title}>
                <Stack
                  h="50px"
                  sx={{
                    background: i.bar,
                  }}
                  w="6px"
                />
                <Stack>
                  <Text
                    color="#FFF"
                    fontFamily="Poppins,sans-serif"
                    fontSize="20px"
                    fontStyle="normal"
                    fontWeight={700}
                    lineHeight="140%"
                  >
                    {i.percent}
                  </Text>
                  <Text
                    color="rgba(255, 255, 255, 0.70)"
                    fontFamily="Poppins,sans-serif"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight={400}
                    lineHeight="140%"
                  >
                    {i.title}
                  </Text>
                </Stack>
              </Flex>
            ))}
          </Stack>
        </Flex>
        <Stack display={isMobile ? 'flex' : isTablet ? 'flex' : 'none'}>
          <Flex justifyContent="flex-start" alignItems="start" gap="30px">
            <Stack justifyContent="center" gap="30px">
              {dataLeft.map((i) => (
                <Flex gap="30px" w="full" key={i.title}>
                  <Stack
                    sx={{
                      background: i.bar,
                    }}
                    w="6px"
                  />
                  <Stack>
                    <Text
                      color="#FFF"
                      fontFamily="Poppins,sans-serif"
                      fontSize="20px"
                      fontStyle="normal"
                      fontWeight={700}
                      lineHeight="140%"
                    >
                      {i.percent}
                    </Text>
                    <Text
                      color="rgba(255, 255, 255, 0.70)"
                      fontFamily="Poppins,sans-serif"
                      fontSize="16px"
                      fontStyle="normal"
                      fontWeight={400}
                      lineHeight="140%"
                    >
                      {i.title}
                    </Text>
                  </Stack>
                </Flex>
              ))}
            </Stack>
            <Stack justifyContent="center" gap="30px">
              {dataRight.map((i) => (
                <Flex gap="30px" w="full" key={i.title}>
                  <Stack
                    sx={{
                      background: i.bar,
                    }}
                    w="6px"
                  />
                  <Stack>
                    <Text
                      color="#FFF"
                      fontFamily="Poppins,sans-serif"
                      fontSize="20px"
                      fontStyle="normal"
                      fontWeight={700}
                      lineHeight="140%"
                    >
                      {i.percent}
                    </Text>
                    <Text
                      color="rgba(255, 255, 255, 0.70)"
                      fontFamily="Poppins,sans-serif"
                      fontSize="16px"
                      fontStyle="normal"
                      fontWeight={400}
                      lineHeight="140%"
                    >
                      {i.title}
                    </Text>
                  </Stack>
                </Flex>
              ))}
            </Stack>
          </Flex>
        </Stack>
        <img style={{ width: '400px' }} src="/images/core/totalSupply.png" />
      </Stack>
    </Wrapper>
  )
}
export default Section4
